import React from "react"
import { Container, Button } from "../index"
import { InstagramLogo, LinkedInLogo } from "../../assets/images/images"

import * as images from "../../assets/images/images"
import { Link, useNavigate } from "react-router-dom"

const linkItems = {
  firstCol: [
    {
      title: "Corporate Solutions",
      slug: "/corporate",
    },
    {
      title: "Landlords",
      slug: "/landlords",
    },
    {
      title: "Tenants",
      slug: "/tenants",
    },
  ],
}

const LinkItem = ({ linkItem, index }) => {
  return (
    <li className={`${index !== 0 ? "mt-2" : ""} font-medium font-roboto text-white`}>
      <Link to={linkItem.slug}>{linkItem.title}</Link>
    </li>
  )
}

// flex flex-col md:flex-row md:pl-10 gap:6 lg:gap-10 py-[60px] border-y-2 border-y-white

const Footer = () => {
  return (
    <div className="w-full">
      <Container className="flex flex-col flex-wrap sm:flex-row md:pl-10 gap-6  lg:gap-10 py-[60px] border-y-2 border-y-white">
        <div className="mb-8 md:mb-0 lg:basis-[25%]">
          {/* LOGO */}
          <img src={images.whiteLogo} alt="logo" className="h-12 object-cover" />

          <div>
            <h3 className="font-roboto font-medium text-white py-4 md:py-8">
              <a className="text-white underline" href="mailto:info@propertise.com">
                info@propertise.com
              </a>
            </h3>
            <h3 className="font-roboto font-medium text-white">Building A1,</h3>
            <h3 className="font-roboto font-medium text-white">Dubai Silicon Oasis,</h3>
            <h3 className="font-roboto font-medium text-white">Dubai, United Arab,</h3>
            <h3 className="font-roboto font-medium text-white">Emirates</h3>
          </div>
        </div>
        <div className="mb-8 md:mb-0 lg:basis-[45%] ">
          <h2 className="text-2xl md:text-3xl leading-[1] font-roboto text-white font-medium">
            Discover Our Solutions
          </h2>
          <div className="flex flex-row gap-8 lg:gap-4">
            <div className="mt-4 md:mt-8 md:flex-grow">
              <ul className="list-disc pl-6">
                {linkItems.firstCol.map((firstColItem, index) => (
                  <LinkItem key={`linkItemsCol1-${index}`} index={index} linkItem={firstColItem} />
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:basis-[22%] ">
          <h2 className="text-2xl md:text-3xl leading-[1] font-roboto text-white font-medium">Contact Us</h2>
          <div>
            <h3 className="font-roboto font-medium text-white mt-4 md:mt-8">
              <a className="text-white underline" href="mailto:info@propertise.com">
                info@propertise.com
              </a>
            </h3>
          </div>
          <div className="mt-8">
            <h2 className="text-2xl md:text-3xl leading-[1] font-roboto text-white font-medium">Follow Us</h2>
            <div className="flex gap-4 mt-4">
              <a href="https://www.instagram.com/propertise.official/" target="_blank">
                <img className="w-8 inline-block object-contain" src={InstagramLogo} alt="Instagram Logo" />
              </a>
              <a href="https://www.linkedin.com/company/propertise" target="_blank">
                <img className="w-8 inline-block object-contain" src={LinkedInLogo} alt="LinkedIn Logo" />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div className="pt-7 flex flex-col sm:flex-row gap-4 sm:gap-36">
          <p className="font-medium text-white  sm:text-[18px] font-roboto">Copyright &#169; Propertise FZCO</p>
          <div className="flex flex-row gap-5 ">
            <Link className="font-medium text-white text-[18px] font-roboto" to="cookie-policy">
              Cookies
            </Link>
            <Link className="font-medium text-white text-[18px] font-roboto" to="privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
