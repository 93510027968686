import React, { useId } from "react"
import { useModel } from "../../context"

const Input = (
  { label, type = "text", labelColor = "text-white", className = "", error, containerStyle = "", ...props },
  ref
) => {
  const id = useId()
  const { isModelOpen } = useModel()

  return (
    <div className={`${containerStyle}`}>
      <div>
        {label && (
          <label
            htmlFor={id}
            className={`inline-block mb-1 pl-1  font-bold font-roboto ${
              isModelOpen ? "text-darkGray" : labelColor
            } ${labelColor}`}
          >
            {label}
          </label>
        )}
      </div>
      <input
        id={id}
        type={type}
        className={`px-3 py-2 rounded w-full bg-transparent text-sm font-arial  outline-none border text-ellipsis ${className}`}
        {...props}
        ref={ref}
      />
    </div>
  )
}

export default React.forwardRef(Input)

// <input
// id={id}
// type={type}
// className={`px-3 py-2 rounded w-full bg-transparent  ${
//   isModelOpen
//     ? "text-darkGray border-darkGray border focus:border-darkBlackgreen  outline-none placeholder:text-darkGray"
//     : " text-white placeholder:text-extraLightGreenColor border-extraLightGreenColor focus:border-white border-opacity-80  focus:placeholder:text-white"
// }  outline-none border font-roboto ${className}`}
// {...props}
// ref={ref}
// />
