import React from "react"
import { Container } from "../components/index"
import { comingSoon } from "../assets/images/images"

const ComingSoon = () => {
  return (
    <div className="w-full h-screen bg-simpleBG bg-cover border-[0] border-transparent">
      <Container className="h-full flex flex-col justify-center items-center">
        <img src={comingSoon} alt="Coming Soon" className="w-[480px] inline-block pb-8" />
        <h1 className="text-2xl md:3xl lg:text-4xl leading-[1] font-roboto text-center  text-white font-extrabold">
          We are building something new!
        </h1>
        <h1 className="text-2xl md:3xl lg:text-4xl leading-[1] font-roboto text-center py-5 text-white font-extrabold">
          Stay tuned for our announcement.
        </h1>
        <h1 className="text-2xl md:3xl lg:text-4xl leading-[1] font-roboto text-center  text-white font-extrabold">
          We promise it's worth the wait!
        </h1>
      </Container>
    </div>
  )
}

export default ComingSoon
