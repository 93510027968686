import React, { useRef, useState, useEffect } from "react"
import { Container } from "../index"
import { featuresItems } from "../../data/home"
import { motion, useInView } from "framer-motion"
import useInViewAnimation from "../../hooks/useInViewAnimation"
import useMediaQuery from "../../hooks/useMediaQuery"

const FeatureItem = ({ featureItem, delay }) => {
  const { ref, visible } = useInViewAnimation(0.5)

  const featureVariant = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 1,
        type: "tween",
      },
    },
  }

  return (
    <motion.div
      ref={ref}
      variants={featureVariant}
      initial="hidden"
      animate={visible}
      className="max-w-[430px] h-auto sm:h-[330px] lg:h-auto py-8 px-2.5 bg-white rounded-2xl shadow-xl  overflow-hidden "
    >
      {/* Icon */}
      <div className="py-3">
        <img src={featureItem.icon} alt="Scalability Icon" className="w-[50px] h-[50px]" />
      </div>
      {/* CARD CONTENT */}
      <div>
        {/* heading */}
        <div>
          <h3 className="font-roboto font-semibold text-darkGray text-[16px] pt-2 pb-3 ">{featureItem.title}</h3>
          {/* description */}
          <p className="font-arial text-base text-darkGray ">{featureItem.content}</p>
        </div>
      </div>
    </motion.div>
  )
}

const Features = () => {
  const { ref, visible } = useInViewAnimation(0)
  const isAbovelargeScreen = useMediaQuery("(min-width:1280px)")
  const isAboveMediumScreen = useMediaQuery("(max-width:1020px) and (min-width:900px)")
  const isAboveMedium2 = useMediaQuery("(max-width:1279px) and (min-width:1021px)")
  // console.log(isAboveMediumScreen)

  const headingVariant = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        type: "tween",
      },
    },
  }

  return (
    // <div className="w-full relative z-30 py-24 bg-verticalBackground">
    // <div className="w-full relative z-30 py-24 bg-lightGreenGradiant4 ">
    <div
      className={`w-full relative py-24  ${isAbovelargeScreen && "bg-lightGreenGradiant4 -top-16 "} ${
        isAboveMediumScreen ? "bg-lightGreenGradiant44 -top-24 z-0 " : "lightGreenGradiant4_2"
      } ${isAboveMedium2 && "bg-lightGreenGradiant44_2 -top-24 z-0 "}`}
    >
      <Container className="grid grid-rows-1 pb-24  relative">
        <motion.h2
          ref={ref}
          variants={headingVariant}
          initial="hidden"
          animate={visible}
          className="text-4xl leading-[1] font-roboto text-white font-medium place-self-center pb-12"
        >
          Manage and achieve it all from one platform
        </motion.h2>
        {/* FEATURES  */}
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 ">
          {/* FEATURE CARDS */}
          {featuresItems.map((featureItem, index) => (
            <FeatureItem
              key={`featureItem-${index}`}
              featureItem={featureItem}
              delay={index <= 4 ? index * 0.2 : index * 0.1}
            />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Features
