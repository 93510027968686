import { Outlet } from "react-router-dom"
import { Navbar, Footer } from "./components"
import { useLocation } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import {
  SolutionProvider,
  ModelProvider,
  DarkModeProvider,
  ScrollContext,
  ScrollProvider,
  useScrollCtx,
} from "./context/index"
import { FaArrowUp } from "react-icons/fa6"
import useMediaQuery from "./hooks/useMediaQuery"
import ReactGA from "react-ga4"
import CookieConsentPopup from "./CookiesConsent"
import "./index.css"
import { getCookieConsentValue } from "react-cookie-consent"

// const isConsent = getCookieConsentValue('myCookieConsent')
// if(isConsent != undefined && isConsent != null)
ReactGA.initialize("G-N6CEFLD65N")

function App() {
  const { pathname } = useLocation()
  const isAboveLargeScreen = useMediaQuery("(min-width:1280px)")
  const [isTopOfPage, setIsTopOfPage] = useState(true)
  const [selectedTab, setSelectedTab] = useState("propertyManagement")
  const [isModelOpen, setIsModelOpen] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [currentPathname, setCurrentPathname] = useState("")
  const [isAlreadyScroll, setIsAlreadyScroll] = useState(false)

  const scrollToggle = () => {
    setIsAlreadyScroll(true)
  }

  const [cookiesAccepted, setCookiesAccepted] = useState(
    getCookieConsentValue("myCookieConsent") != undefined ? getCookieConsentValue("myCookieConsent") : false
  )

  const handleCookiesAccepted = () => {
    setCookiesAccepted(true)
  }
  const toggleDarkMode = (tab) => {
    setIsDarkMode(!isDarkMode)
  }
  const toggleSelectedTab = (tab) => {
    setSelectedTab(tab)
  }
  const toggleIsModel = () => {
    setIsModelOpen(!isModelOpen)
  }

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY
      if (position == 0) setIsTopOfPage(true)
      if (position != 0) setIsTopOfPage(false)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    setCurrentPathname(pathname)
  }, [pathname])

  const ScrollToTop = ({ pathname }) => {
    useEffect(() => {
      const newRouterPath = "/"
      if (currentPathname !== newRouterPath) {
        window.scrollTo(0, 0)
      } else if (currentPathname === "/" && !isAlreadyScroll) {
        window.scrollTo(0, 0)
      }
    }, [pathname])
    return null
  }

  const ScrollToButton = () => {
    const [isScollBtn, setIsScrollBtn] = useState(false)
    useEffect(() => {
      const handleScroll = () => {
        const position = window.scrollY
        if (position > 500) setIsScrollBtn(true)
        else setIsScrollBtn(false)
      }

      window.addEventListener("scroll", handleScroll)
      return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    if (!isScollBtn) return ""
    return (
      <div className="fixed top-[90%] right-3 z-50">
        <button
          className="w-12 h-12 text-white text-xl rounded-full  bg-lightGreen flex justify-center items-center"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
        >
          <FaArrowUp />
        </button>
      </div>
    )
  }

  // useEffect(() => {
  //   const disableRightClick = (event) => {
  //     event.preventDefault()
  //   }

  //   const disableImageDrag = (event) => {
  //     event.preventDefault()
  //   }

  //   const handleImageElements = () => {
  //     const images = document.querySelectorAll("img")
  //     images.forEach((img) => {
  //       img.addEventListener("contextmenu", disableRightClick)
  //       img.addEventListener("dragstart", disableImageDrag)
  //     })
  //   }

  //   handleImageElements() // Call initially when component mounts

  //   return () => {
  //     const images = document.querySelectorAll("img")
  //     images.forEach((img) => {
  //       img.removeEventListener("contextmenu", disableRightClick)
  //       img.removeEventListener("dragstart", disableImageDrag)
  //     })
  //   }
  // })

  return (
    <>
      {!cookiesAccepted && (
        <div className="consent-form">
          <CookieConsentPopup onAccept={handleCookiesAccepted} />
        </div>
      )}
      <div className={!cookiesAccepted ? "blur-overlay" : ""}>
        <ScrollProvider value={{ isAlreadyScroll, scrollToggle }}>
          <DarkModeProvider value={{ isDarkMode, toggleDarkMode }}>
            <ModelProvider value={{ isModelOpen, toggleIsModel }}>
              <SolutionProvider value={{ selectedTab, toggleSelectedTab }}>
                {/* {isModelOpen && <Login />} */}
                <div className={`app  ${isModelOpen ? "overflow-hidden" : ""}`}>
                  {/* <div className={`w-full relative ${!isAboveLargeScreen && "bg-background2"}`}> */}
                  <ScrollToTop pathname={pathname} />
                  <div className={`w-full relative`}>
                    <ScrollToButton />
                    {/* header */}
                    <header>{<Navbar isTopOfPage={isTopOfPage} />}</header>
                    <main>
                      <Outlet />
                    </main>
                    {/* footer */}
                    {/* <footer className={`relative bg py-10 bg-backgrounWithImage bg-contain bg-center pt-[300px] z-30`}> */}
                    <footer
                      className={`relative bg py-10 bg-backgrounWithImage bg-contain bg-center z-30 ${
                        ["/coming-soon"].includes(pathname) ? "pt-[0px] py-0 mt-0 bg-simpleBG border-t-0" : "pt-[300px]"
                      }`}
                    >
                      <Footer />
                    </footer>
                  </div>
                </div>
              </SolutionProvider>
            </ModelProvider>
          </DarkModeProvider>
        </ScrollProvider>
      </div>
    </>
  )
}

export default App

{
  /* <footer
className={`relative bg py-10 bg-backgrounWithImage bg-contain bg-center z-30 ${
  ["/contact", "/coming-soon"].includes(pathname)
    ? "pt-[0px] py-0 mt-0 bg-simpleBG border-t-0"
    : "pt-[300px]"
}`}
>
{!["/solutions", "/contact", "/coming-soon", "/landlords", "/tenants"].includes(pathname) && (
  <CallToAction />
)}
<Footer />

</footer> */
}

{
  /* <footer
className={`relative bg py-10 bg-backgrounWithImage bg-contain bg-center z-30 ${
  ["/contact", "/coming-soon"].includes(pathname)
    ? "pt-[0px] py-0 mt-0 bg-simpleBG border-t-0"
    : "pt-[300px]"
}`}
>
{!["/solutions", "/contact", "/coming-soon"].includes(pathname) && <CallToAction />}
<Footer />
</footer> */
}

// <footer className={`relative pt-[300px] bg-backgrounWithImage bg-contain bg-center z-30`}>
//                 {/* {!["/solutions", "/contact", "/comings-oon"].includes(pathname) && <CallToAction />} */}

//                 <Footer />
//               </footer>
