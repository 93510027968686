import React from "react"
import Container from "./Container"
import { TouchConfirmDK } from "../../assets/icons/icons"

const HorizentalFormConfirmation = () => {
  return (
    <div className="bg-doubleExtraGreenColor py-8 px-12 rounded-lg w-[70%] absolute -bottom-[100px] left-[50%] translate-x-[-50%] z-40 shadow-cardShadow">
      <Container className="flex flex-col items-center justify-center text-center">
        <span>
          <img src={TouchConfirmDK} alt="Confirmation Icon" className="w-10 h-10" />
        </span>
        <p className="font-roboto font-bold text-xl w-[80%] text-darkGray mt-8">
          Thank you for expressing your interest in Propertise, we have received your registration.
        </p>
        <p className="font-roboto font-bold text-xl w-[80%] border-b border-darkGray pb-4 text-darkGray mt-4">
          Stay tuned for exciting updates as we strive to make your Propertise experience worth the wait!
        </p>
      </Container>
    </div>
  )
}

export default HorizentalFormConfirmation
