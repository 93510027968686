import React, { useState, useRef } from "react"
import { Hero, Features, Services, Button, Container } from "../components"
import MidSection from "../components/home/MidSection"
import { RegistrationForm, HorizentalFormConfirmation, EventCard } from "../components"
import axiosIns from "../plugins/axios"
import { userControlItems } from "../data/home"
import { useNavigate } from "react-router-dom"
import ReactGA from "react-ga4"
import useMediaQuery from "../hooks/useMediaQuery"
import useInViewAnimation from "../hooks/useInViewAnimation"
import { useScrollCtx } from "../context"

const Home = () => {
  // const { ref, visible } = useInViewAnimation(0.5)
  const isAboveMediumScreen = useMediaQuery("(min-width:900px)")
  const [isHorizentalFormSubmittion, setIsHorizentalFormSubmittion] = useState(false)
  const [isFullForm, setIsFullForm] = useState(false)
  const { isAlreadyScroll, scrollToggle } = useScrollCtx()
  const navigate = useNavigate()
  const resetFormSubmittion = () => {
    setTimeout(() => {
      setIsHorizentalFormSubmittion(false)
    }, 3000)
  }

  // const serviceVariant = {
  //   hidden: {
  //     opacity: 0,
  //     y: 80,
  //   },
  //   visible: {
  //     opacity: 1,
  //     y: 0,
  //     transition: {
  //       delay: 1,
  //       duration: 1,
  //       type: "tween",
  //     },
  //   },
  // }

  const handleSubmit = (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endPoint = "landing_page/contact_us"
        const {
          status,
          data: { data = {} },
        } = await axiosIns.post(endPoint, {
          ...formData,
          sourceType: 0,
          campaignName: "Pre-Launched-Event",
        })
        if (status === 200) {
          resolve("Successfully Submitted")
          resetFormSubmittion()
          ReactGA.event({
            category: "Registration Form",
            action: "registration_form_submitted",
          })
        }
      } catch (error) {
        reject(error.message)
      }
    })
  }

  function scrollToRegisterForm(event) {
    event.stopPropagation()
    let section = document.getElementById("registerForm")
    scrollToggle()
    section.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className="relative">
      {/* <div className={`relative ${!isAboveMediumScreen && "bg-background2"}`}> */}
      <div className={`relative ${!isAboveMediumScreen && "bg-background2"}`}>
        <Hero scrollToRegisterForm={scrollToRegisterForm} />
        <Features />
      </div>

      {/* <Services /> */}

      <div className="relative w-full mt-10 mb-20 md:mt-14">
        <div className="relative bg-white">
          <div className="absolute -top-[160px] md:-top-[150px] left-1/2 transform -translate-x-1/2 z-30  flex-col gap-4 flex items-center justify-center ">
            <Button
              bgColor="bg-[#0D9E91]"
              className="font-medium w-80 font-roboto rounded-lg px-10 py-2"
              onClick={() => navigate("/corporate")}
            >
              Explore Our Corporate Solution
            </Button>
            <Button
              textColor="text-darkBlackgreen"
              bgColor="bg-transparent"
              className="font-medium font-roboto w-80 rounded-lg px-10 py-2 border border-darkBlackgreen"
              onClick={scrollToRegisterForm}
            >
              Register Your Interest
            </Button>
            <h1 className="text-xl md:2xl lg:text-3xl leading-[1.2] font-roboto text-center  text-darkGray font-bold pt-2">
              Coming Soon!
            </h1>
            <p className="text-xl sm:text-2xl  font-roboto text-center pb-5 text-darkGray font-bold mt-8">
              Elevate Your Landlord Experience And Stay In Control Of Your Property
            </p>
          </div>
        </div>
        <div className="relative py-20">
          <div className="absolute top-0 bottom-0 z-20 w-full h-full bg-midWhiteGradiant" />
          {userControlItems.rightView.map((item, index) => (
            <div className="relative py-20" key={`userControlItem-${index}`}>
              {(index + 1) % 2 === 0 ? <div className="banner2"></div> : <div className="banner1"></div>}

              <MidSection
                title={item.title}
                description={item.description}
                sideImage1={item.sideImage1}
                sideImage2={item.sideImage2}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="relative mt-10 md:mt-0 pt-20 ">
        <div className="relative bg-white">
          <div className="absolute -top-[170px] md:-top-[150px]  left-1/2 transform -translate-x-1/2 z-30  flex-col gap-4 flex items-center justify-center">
            <Button
              bgColor="bg-[#0D9E91]"
              className="font-medium w-80 font-roboto rounded-lg px-8 py-2"
              onClick={() => navigate("/landlords")}
            >
              Discover Propertise for Landlords
            </Button>
            <Button
              textColor="text-darkBlackgreen"
              bgColor="bg-transparent"
              className="font-medium font-roboto w-80 rounded-lg px-10 py-2 border border-darkBlackgreen"
              onClick={scrollToRegisterForm}
            >
              Register Your Interest
            </Button>
            <h1 className="text-xl md:2xl lg:text-3xl leading-[1.2] font-roboto text-center  text-darkGray font-bold pt-4">
              Coming Soon!
            </h1>
            <p className="text-xl sm:text-2xl font-roboto text-center text-darkGray font-bold pt-8">
              Uplift Your Tenant Experience And Unlock A World Of Convenience And Satisfaction
            </p>
          </div>
        </div>
        <div className="relative py-20">
          <div className="absolute top-0 bottom-0 z-20 w-full h-full bg-midWhiteGradiant" />
          {userControlItems.leftView.map((item, index) => (
            <div className="relative   py-10 lg:py-20" key={`userControlItem-${index}`}>
              {(index + 1) % 2 === 0 ? (
                <div className="banner2 absolute top-0 bottom-0"></div>
              ) : (
                <div className="banner1 absolute top-0 bottom-0"></div>
              )}

              <MidSection
                isLeft={item.isLeftAlign}
                title={item.title}
                description={item.description}
                sideImage1={item.sideImage1}
                sideImage2={item.sideImage2}
              />
            </div>
          ))}
        </div>
        <div className="relative bg-white mt-10 md:mt-12">
          <div className="absolute bg-white -bottom-[140px] md:-bottom-[130px] left-1/2 transform -translate-x-1/2 z-30  flex-col gap-4 flex items-center justify-center ">
            <Button
              bgColor="bg-[#0D9E91]"
              className="font-medium w-80 font-roboto rounded-lg px-10 py-2"
              onClick={() => navigate("/tenants")}
            >
              Discover Propertise for Tenants
            </Button>
            <Button
              textColor="text-darkBlackgreen"
              bgColor="bg-transparent"
              className="font-medium font-roboto w-80 rounded-lg px-10 py-2 border border-darkBlackgreen"
              onClick={scrollToRegisterForm}
            >
              Register Your Interest
            </Button>
            <h1 className="text-xl md:2xl lg:text-3xl leading-[1.2] font-roboto text-center  text-darkGray font-bold pt-4">
              Coming Soon!
            </h1>
          </div>
        </div>
      </div>
      <div className="pt-[220px]">
        <h1 className="text-xl md:2xl lg:text-3xl leading-[1.2] font-roboto text-center  text-darkGray font-bold pt-4">
          Meet Us!
        </h1>
        <p className="text-lg  font-roboto text-center pb-5 text-darkGray font-bold pt-4">
          Stay tuned as we reveal where we’re going to be next
        </p>

        <Container className={`pt-2 ${isFullForm && "pb-8"}`}>
          <div className="w-full flex  flex-wrap md:flex-nowrap gap-5 justify-between">
            <EventCard delay={0.5} isFirst={true} />
            <EventCard delay={1} />
            <EventCard delay={1.5} />
          </div>
        </Container>
      </div>

      <div id="registerForm" className={`${isFullForm ? "pt-[950px] md:pt-[500px]" : "pt-[300px]"} `}>
        {isHorizentalFormSubmittion ? (
          <HorizentalFormConfirmation />
        ) : (
          <RegistrationForm
            setIsFullForm={setIsFullForm}
            onSubmit={handleSubmit}
            setIsHorizentalFormSubmittion={setIsHorizentalFormSubmittion}
          />
        )}
      </div>

      {/* <div className="relative w-full ">
        <div className="absolute top-0 bottom-0 z-20 w-full bg-midWhiteGradiant" />
        <div className="relative py-10">
          <div className="banner1 absolute top-0 bottom-0"></div>
          <MidSection backgroundImage="bg-midSectionBanner" />
        </div>
        <div className="relative py-10">
          <div className="banner2 absolute top-0 bottom-0"></div>
          <MidSection backgroundImage="bg-midSectionBannerFlip" />
        </div>
        <div className="relative py-10">
          <div className="banner1 absolute top-0 bottom-0"></div>
          <MidSection backgroundImage="bg-midSectionBanner" />
        </div>
        <div className="relative py-10">
          <div className="banner2 absolute top-0 bottom-0"></div>
          <MidSection backgroundImage="bg-midSectionBannerFlip" />
        </div>
      </div> */}
    </div>
  )
}

export default Home
