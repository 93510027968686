import { useInView } from "framer-motion"
import { useEffect, useRef, useState } from "react"

const useInViewAnimation = (threshold = 0) => {
  const [hasAnimated, setHasAnimated] = useState(false)
  const [visible, setVisible] = useState("")
  const ref = useRef()
  const isInView = useInView(ref, { threshold })

  useEffect(() => {
    if (isInView && !hasAnimated) {
      setHasAnimated(true)
      setVisible("visible")
    }
  }, [isInView])

  return { ref, visible }
}

export default useInViewAnimation
