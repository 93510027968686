import React from "react"
import { motion } from "framer-motion"
import useInViewAnimation from "../../hooks/useInViewAnimation"

const MidSection = ({ sideImage1 = "", sideImage2 = "", title = "", description = "", isLeft = false }) => {
  const { ref, visible } = useInViewAnimation(0.5)
  const serviceVariant = {
    hidden: {
      opacity: 0,
      y: 80,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.2,
        duration: 1,
        type: "tween",
      },
    },
  }
  return (
    // <div className="max-w-[90%] mx-auto">
    <motion.div
      ref={ref}
      variants={serviceVariant}
      initial="hidden"
      animate={visible}
      className="flex flex-col lg:flex-row items-center relative py-10 z-30 w-full h-full mt-8 md:mt-0"
    >
      <div className={`z-20 flex gap-4 basis-[45%] grow shrink-0  justify-center ${isLeft ? "lg:order-2" : ""}`}>
        <img src={sideImage1} className="w-[22%] md:w-[18%] lg:w-[35%] inline-block" alt="asdf" />
        <img src={sideImage2} className="w-[22%] md:w-[18%] lg:w-[35%] inline-block" alt="asdf" />
      </div>
      <div className={`z-20 basis-[55%] shrink-0 grow  my-8 lg:my-0 ${isLeft ? "flex justify-end" : ""} `}>
        <div className="w-[85%] mx-auto lg:mx-0 lg:w-[80%] xl:w-[70%]">
          <p className="py-2 font-roboto text-xl font-bold text-darkGray">{title}</p>
          <p className="font-arial text-[19px] text-darkGray mt-4">{description}</p>
        </div>
      </div>
    </motion.div>
    // </div>
  )
}

export default MidSection

{
  /* <div className="w-full relative">
        <div
          className="absolute inset-0 bg-midSectionBanner bg-cover"
          style={{ zIndex: "-1", transform: "scaleY(-1)" }}
        />
        <div className="flex gap-10 py-20">
          <div>
            <img src={PropertyManagementImg11} className="w-full" alt="asdf" />
          </div>
          <div className="z-20">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat adipisci, itaque repudiandae assumenda
              cumque explicabo.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti laborum exercitationem facere nisi
              fugit tenetur inventore beatae in placeat sed, ducimus at reprehenderit odit quisquam blanditiis nulla
              voluptas molestiae iste et. Molestias exercitationem optio illo ducimus quis non, labore eligendi culpa
              mollitia veritatis nisi error distinctio explicabo eos nemo enim. Ex perspiciatis iste non saepe similique
              dolores enim nihil soluta blanditiis. Facilis optio ad veritatis, iusto sint sed molestiae vitae quidem
              vero aperiam eius sunt quo commodi voluptatum inventore minima minus itaque vel non deleniti laboriosam.
              Ducimus sequi omnis optio nostrum, laborum, beatae nesciunt voluptates deleniti hic accusantium enim
              dolorem?
            </p>
          </div>
        </div>
      </div> */
}
