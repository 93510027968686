import React from "react"
import { Solution } from "../components"

const Solutions = () => {
  return (
    <div>
      <Solution />
    </div>
  )
}

export default Solutions
