import React, { useState, useEffect } from "react"
import { Container, Input, PhoneNumber, MultiSelectDropdown, Button } from "../index"
import { useForm, Controller } from "react-hook-form"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"

const textAreaLabelQuestion = "Have a feature in mind that you would like to experience with Propertise?"
const textAreaLabelAnswer = "Share with us your wishlist and we’ll make sure to exceed your expectations!"

const HorizentalForm = ({ onSubmit, setIsHorizentalFormSubmittion, textAreaPlaceholder = "", isWorkEmail = false }) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)
  const { pathname } = useLocation()
  const [serviceItems, setServiceItems] = useState([
    { value: "property management", label: "Property Management" },
    { value: "leasing", label: "Leasing" },
    { value: "tenants", label: "Tenants" },
    { value: "maintenance", label: "Maintenance" },
    { value: "accounting", label: "Accounting" },
  ])
  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({ firstName: "", lastName: "", email: "", companyName: "", message: "", featureList: [] })

  const submitHandler = (data) => {
    setIsBtnDisabled(true)
    onSubmit(data)
      .then((message) => {
        setIsBtnDisabled(false)
        reset({ firstName: "", lastName: "", email: "", companyName: "", message: "", featureList: [] })
        setIsHorizentalFormSubmittion(true)
      })
      .catch((error) => {
        console.log(error)
        setIsBtnDisabled(false)
      })
  }

  const [filledFirstName, setFilledFirstName] = useState(false)
  const [filledLastName, setFilledLastName] = useState(false)
  const [filledEmail, setFilledEmail] = useState(false)
  const [filledPhoneNumber, setFilledPhoneNumber] = useState(false)
  const [filledCompany, setFilledCompany] = useState(false)
  const [filledMessage, setFilledMessage] = useState(false)
  const [filledFeatureList, setFeatureList] = useState(false)
  const [filledCompanyEmail, setFilledCompanyEmail] = useState(false)

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (value.firstName) {
        setFilledFirstName(true)
      } else {
        setFilledFirstName(false)
      }
      if (value.lastName) {
        setFilledLastName(true)
      } else {
        setFilledLastName(false)
      }
      if (value.email) {
        setFilledEmail(true)
      } else {
        setFilledEmail(false)
      }
      if (value.companyEmail) {
        setFilledCompanyEmail(true)
      } else {
        setFilledCompanyEmail(false)
      }
      if (value.phoneNumber) {
        setFilledPhoneNumber(true)
      } else {
        setFilledPhoneNumber(false)
      }
      if (value.companyName) {
        setFilledCompany(true)
      } else {
        setFilledCompany(false)
      }
      if (value.message) {
        setFilledMessage(true)
      } else {
        setFilledMessage(false)
      }
      if (value?.featureList?.length > 0) {
        setFeatureList(true)
      } else {
        setFeatureList(false)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <div className="bg-doubleExtraGreenColor py-8 px-12 rounded-lg w-[90%] md:w-[70%] absolute -bottom-[100px] left-[50%] translate-x-[-50%] z-40 shadow-cardShadow">
      <form onSubmit={handleSubmit(submitHandler)}>
        <h1 className="text-xl md:text-2xl font-roboto  text-darkGray font-bold mb-5">
          Register now and gain exclusive access to our latest announcements, ensuring you stay informed and ahead of
          the curve.
        </h1>
        <div className="flex flex-col">
          <div className="flex gap-4 flex-wrap md:flex-nowrap mb-4">
            {/* FIRST NAME */}
            <div className="w-full">
              <Input
                name="firstName"
                label="First Name"
                type="text"
                labelColor="text-darkGray"
                className={`text-darkGray text-opacity-100  placeholder:text-darkGray placeholder:text-opacity-60 focus:border-darkGray hover:border-darkGray  focus:placeholder:text-darkGray ${
                  filledFirstName
                    ? "border-darkGray border-opacity-100 border-[2.8px]"
                    : "border-darkGray border-opacity-60"
                }`}
                placeholder="Enter your first name"
                autoComplete="off"
                {...register("firstName", {
                  required: true,
                })}
              />
              {errors.firstName?.type === "required" && (
                <p className="text-red-600 ml-2 text-sm">First name is required</p>
              )}
            </div>
            {/*LAST NAME */}
            <div className=" w-full">
              <Input
                name="lastName"
                label="Last Name"
                type="text"
                labelColor="text-darkGray"
                className={`text-darkGray text-opacity-100 placeholder:text-darkGray placeholder:text-opacity-60  focus:border-darkGray hover:border-darkGray  focus:placeholder:text-darkGray ${
                  filledLastName
                    ? "border-darkGray border-opacity-100 border-[2.8px]"
                    : "border-darkGray border-opacity-60"
                }`}
                placeholder="Enter your last name"
                autoComplete="off"
                {...register("lastName", {
                  required: true,
                })}
              />
              {errors.lastName?.type === "required" && (
                <p className="text-red-600 ml-2 text-sm">Last name is required</p>
              )}
            </div>
            {/* EMAIL */}
            {!isWorkEmail && (
              <div className=" w-full">
                <Input
                  name="email"
                  label="Email"
                  type="text"
                  placeholder="Enter your email"
                  labelColor="text-darkGray"
                  className={`text-darkGray text-opacity-100 placeholder:text-darkGray placeholder:text-opacity-60  focus:border-darkGray hover:border-darkGray  focus:placeholder:text-darkGray ${
                    filledEmail
                      ? "border-darkGray border-opacity-100 border-[2.8px]"
                      : "border-darkGray border-opacity-60"
                  }`}
                  autoComplete="off"
                  {...register("email", {
                    required: true,
                    validate: {
                      matchPattern: (value) =>
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                          value
                        ) || "Not a valid email",
                    },
                  })}
                />
                {errors.email?.type === "required" && <p className="text-red-600 ml-2 text-sm">Email is required</p>}
                {errors.email?.type === "matchPattern" && (
                  <p className="text-red-600 ml-2 text-sm">{errors.email?.message}</p>
                )}
              </div>
            )}
            {/* EMAIL */}
            {isWorkEmail && (
              <div className=" w-full">
                <Input
                  name="companyEmail"
                  label="Work Email"
                  type="text"
                  placeholder="Enter your email"
                  labelColor="text-darkGray"
                  className={`text-darkGray text-opacity-100 placeholder:text-darkGray placeholder:text-opacity-60  focus:border-darkGray hover:border-darkGray  focus:placeholder:text-darkGray ${
                    filledCompanyEmail
                      ? "border-darkGray border-opacity-100 border-[2.8px]"
                      : "border-darkGray border-opacity-60"
                  }`}
                  autoComplete="off"
                  {...register("companyEmail", {
                    required: true,
                    validate: {
                      matchPattern: (value) =>
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                          value
                        ) || "Not a valid email",
                    },
                  })}
                />
                {errors.companyEmail?.type === "required" && (
                  <p className="text-red-600 ml-2 text-sm">Work email is required</p>
                )}
                {errors.companyEmail?.type === "matchPattern" && (
                  <p className="text-red-600 ml-2 text-sm">{errors.companyEmail?.message}</p>
                )}
              </div>
            )}

            {/* PHONE NO */}
            <div className=" w-full">
              <label className="inline-block mb-1 pl-1 text-darkGray font-bold font-roboto">Mobile</label>
              <Controller
                name="phoneNumber"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <PhoneNumber
                    field={field}
                    textColor="#4d505d"
                    borderColor={`${filledPhoneNumber ? "rgba(77, 80, 93,1)" : "rgba(77, 80, 93,0.6)"}`}
                    focusBorderColor="rgba(77, 80, 93,1)"
                    borderWidth={2.8}
                    isPhoneNumberFilled={filledPhoneNumber}
                    customStyle={filledPhoneNumber ? "styleGrayBold" : "styleGray"}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div>
            <div className="flex flex-wrap items-start md:flex-nowrap gap-4 mb-4">
              {/* COMPANY NAME */}
              {!["/landlords", "/tenants"].includes(pathname) && (
                <div className="w-full lg:w-[23.8%]">
                  <Input
                    labelColor="text-darkGray"
                    className={`text-darkGray text-opacity-100  placeholder:text-darkGray placeholder:text-opacity-60 focus:border-darkGray hover:border-darkGray focus:placeholder:text-darkGray ${
                      filledCompany
                        ? "border-darkGray border-opacity-100 border-[2.8px]"
                        : "border-darkGray border-opacity-60"
                    }`}
                    name="companyName"
                    label="Company"
                    type="text"
                    placeholder="Enter company name"
                    autoComplete="off"
                    {...register("companyName", { required: true })}
                  />
                  {errors.companyName?.type === "required" && (
                    <p className="text-red-600 ml-2 text-sm">Company name is required</p>
                  )}
                </div>
              )}

              {!["/landlords", "/tenants"].includes(pathname) && (
                <div className="w-full md:x-[50%] xl:w-[40%]">
                  <MultiSelectDropdown
                    control={control}
                    options={serviceItems}
                    labelColor="text-darkGray"
                    label="Which features are you interested in?"
                    focusBorderColor="rgba(77, 80, 93,1)"
                    borderColor="rgba(77, 80, 93,0.6)"
                    isVertical={false}
                    filledBorderColor={filledFeatureList}
                    borderWidth={2.8}
                    styleSelect="darkStyle"
                    filledFeatureList={filledFeatureList}
                  />
                </div>
              )}
            </div>

            <div className="mb-4 w-full">
              <label htmlFor="message" className="inline-block mb-1 pl-1 text-darkGray font-bold font-arial">
                {textAreaPlaceholder}:
              </label>
              <textarea
                placeholder="Share your wishlist with us, and we'll ensure that Propertise exceeds your expectations…"
                id="message"
                name="message"
                rows={4}
                autoComplete="off"
                className={`px-3 py-2 rounded w-full bg-transparent border focus:border-darkGray focus:border-opacity-100 hover:border-darkGray text-darkGray placeholder:text-opacity-60 outline-none placeholder:text-darkGray font-arial text-sm resize-none ${
                  filledMessage
                    ? "border-darkGray border-opacity-100 border-[2.8px]"
                    : "border-darkGray border-opacity-60"
                }`}
                {...register("message")}
              ></textarea>
              {errors.message?.type === "required" && <p className="text-red-600 ml-2 text-sm">Message is required</p>}
            </div>
            <div className="py-3 font-arial text-sm text-darkGray">
              *By providing your personal information and clicking register, you agree to our{" "}
              <Link className="underline" to="/privacy-policy">
                privacy policy
              </Link>
            </div>
            <div className="className mt-6 w-full">
              <Button
                disabled={isBtnDisabled}
                textColor={isBtnDisabled ? "text-white text-opacity-50" : "text-white"}
                bgColor={isBtnDisabled ? "bg-darkBlackgreen bg-opacity-50" : "bg-darkBlackgreen "}
                className="w-full py-2 rounded font-roboto text-base"
                type="submit"
              >
                Register
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default HorizentalForm
