import React from "react"
import { FaArrowRightLong } from "react-icons/fa6"

const Button = ({
  children,
  type = "button",
  bgColor = "bg-transparent",
  textColor = "text-white",
  className = "",
  isIcon = false,
  iconColor = "",
  iconSize,
  ...props
}) => {
  return (
    <button
      type={type}
      className={`${bgColor} ${textColor} ${className} ${isIcon ? "flex items-center gap-4 " : ""}`}
      {...props}
    >
      {children}
      {isIcon && <FaArrowRightLong color={iconColor} size={iconSize} />}
    </button>
  )
}

export default Button
