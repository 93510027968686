// home MobileScreeens
import {
  MobileScreen2,
  MobileScreen3,
  MobileScreen5,
  MobileScreen8,
  MobileScreen9,
  MobileScreen10,
  MobileScreen13,
  MobileScreen14,
  MobileScreen15,
  MobileScreen17,
  MobileScreen18,
  MobileScreen19,
} from "../../assets/images/images"

// service images
import {
  Service1,
  Service2,
  Service3,
  Service4,
  Service5,
  Service6,
  Service7,
  Service8,
  Service9,
  Service10,
} from "../../assets/images/images"

// featureIcons
import {
  Icon1,
  Icon1Dark,
  Icon2,
  Icon2Dark,
  Icon3,
  Icon3Dark,
  Icon4,
  Icon4Dark,
  Icon5,
  Icon5Dark,
  Icon6,
  Icon6Dark,
  Icon7,
  Icon7Dark,
  Icon8,
  Icon8Dark,
  Icon9,
  Icon9Dark,
  Icon10,
  Icon10Dark,
} from "../../assets/icons/icons"

export const serviceItems = [
  {
    image: Service1,
    category: "propertyManagement",
    title: "Manage your entire portfolio",
    content:
      "Manage your entire portfolio from one platform, get up and running in no time and access key performance indicators, insights and reports to turn every decision into a successful action.",
  },
  {
    image: Service2,
    title: "Lease your units",
    category: "leasing",
    content:
      "Maintain and increase your occupancy rates by gaining full control and clarity on your leases. Generate contracts that reflect your business requirements and offer multiple payment options that suit you and your tenants and save time on rent collections by automating rent payments and reminders.",
  },
  {
    image: Service3,
    title: "Keep your tenants happy",
    category: "tenants",
    content:
      "Connect with your tenants and let them know you care. Offer them one portal to settle payments and lodge their requests, suggestions and complaints while scheduling and sending out important announcements and reminders. Giving you full clarity and control on maintaining and improving your tenant relationships and experience.",
  },
  {
    image: Service4,
    title: "Manage maintenance requests",
    category: "maintenance",
    content:
      "Generate and receive maintenance requests, mobilize your internal or external teams effectively by centrally delegating tasks and jobs efficiently, while keeping a full eye on requests statuses and running costs ensuring you close out requests within set SLAs and maintain healthy budget control and cost savings.",
  },
  {
    image: Service5,
    title: "Accounting made easy",
    category: "accounting",
    content:
      "Record your incoming payments (receivables) and expenses (payables) and keep an accurate account of your financial records while saving time by automating and digitizing tasks such as approvals, late payment fees, generating invoices, receipts, statement reports and more.",
  },
  {
    image: Service6,
    title: "ERP",
    category: "erp",
    content:
      "Bring your property management processes and manage your resources online by creating, customizing and managing your workflows and approval processes. Add users and set their access roles and permissions to ensure clarity in their objectives and keep your teams synced and aligned.",
  },
  {
    image: Service7,
    title: "Manage your day-today tasks",
    category: "tasks",
    content:
      "Create, customize, organize and manage your individual tasks list, allowing you to keep a full view on all your tasks and their statuses so you get more done.",
  },
  {
    image: Service8,
    title: "Keep your vendors informed",
    category: "vendors",
    content:
      "Add and manage your vendors from a single source and sync with them by delegating requests and reviewing their performance on tasks handled.",
  },
  {
    image: Service9,
    title: "Security",
    category: "security",
    content:
      "Keep your data secure through access management, account verifications, device authorizations, data back ups and more.",
  },
  {
    image: Service10,
    title: "Our team at your service",
    category: "customerSupport",
    content:
      "Get the right support for you and your team as our commitment is to ensure you focus on your business and achieving your day-to-day and periodic objectives.",
  },
]

export const featuresItems = [
  {
    icon: Icon4,
    title: "Scalability",
    content:
      "If you are managing ten or thousands of units, you have the tools you need to help you manage and grow your portfolio.",
  },
  {
    icon: Icon2,
    title: "Streamlined Operations",
    content: "Automate and run day-to-day tasks smoothly saving you time, effort and cost. ",
  },
  {
    icon: Icon3,
    title: "Seamless Workflows",
    content:
      "Whether it's rent collection, maintenance requests, or lease renewals, you can integrate your workflows with the relevant departments.",
  },
  {
    icon: Icon5,
    title: "Reduced Downtime",
    content: "Gain full clarity on initiated requests and ensure they are handled swiftly and closed successfully. ",
  },
  {
    icon: Icon6,
    title: "Centralized Data",
    content:
      "Have your property data and documents centralized under one secure platform, making it easier to access and reference.",
  },
  {
    icon: Icon7,
    title: "Enhanced Communication",
    content:
      "Communicate with your stakeholders using a central communication channel and manage your conversations and announcements effectively.",
  },
  {
    icon: Icon8,
    title: "Tenant Satisfaction",
    content:
      "Improve client service by offering tenants a self-service portal for requests and payments, leading to increased retention and satisfaction.",
  },
  {
    icon: Icon9,
    title: "Financial Management",
    content:
      "Maintain accurate financial records and conduct budgeting and forecasting exercises swiftly, and control costs across your business operations.",
  },
  {
    icon: Icon10,
    title: "Informed Decision Making",
    content:
      "Access key data analytics, insights and reporting anytime, anywhere and make informed and agile decisions driven by data and key indicators.",
  },
  {
    icon: Icon1,
    title: "Growth and Efficiency",
    content: "Take your business to the next level by reducing manual dependencies.",
  },
]

export const userControlItems = {
  rightView: [
    {
      title: "Experience the ultimate convenience with hassle-free rent collections",
      description:
        "Say goodbye to tedious bank queues, bounced cheques and dependencies on third-party cheque deposits. With Propertise, your rent payments are seamlessly and securely received directly into your bank account, putting you in control of your finances anytime, anywhere.",
      sideImage1: MobileScreen2,
      sideImage2: MobileScreen10,
      isLeftAlign: false,
    },
    {
      title: "Unlock the power of effortless lease management",
      description:
        "From move-ins to move-outs to renewals and everything in between, take full control of your leasing process with ease. With our intuitive platform, you can effortlessly navigate your requirements and store all required documents in one place, while seamlessly accommodating your tenants' needs.",
      sideImage1: MobileScreen3,
      sideImage2: MobileScreen8,
      isLeftAlign: false,
    },

    {
      title: "Don't leave tenant satisfaction to chance – take proactive steps and watch your investment thrive!",
      description:
        "We understand that happy tenants are the cornerstone of your success, impacting your return on investment. With Propertise, you'll gain invaluable insights into your tenants' satisfaction levels through feedback and ratings, empowering you to make informed decisions that prioritize tenant happiness.",
      sideImage1: MobileScreen5,
      sideImage2: MobileScreen9,
      isLeftAlign: false,
    },
  ],
  leftView: [
    {
      title: "Embrace the future of rent payments with hassle-free, cash-less, and cheque-less transactions!",
      description:
        "Say goodbye to the inconvenience of traditional payment methods and hello to the freedom of settling your rent and other dues directly to your landlord using your credit or debit card. You’ll not only simplify your financial transactions but also unlock the opportunity to earn valuable points on your card with every payment made.",
      sideImage1: MobileScreen14,
      sideImage2: MobileScreen13,
      isLeftAlign: true,
    },
    {
      title: "Manage your requests smoothly and conveniently and enjoy a streamlined leasing experience",
      description:
        "As a valued tenant, you can effortlessly submit and track all your requests directly to your landlord, ensuring they're never overlooked or misplaced. Plus, with instant access to key lease documents at your fingertips, you'll have peace of mind knowing all your leasing needs are met with ease.",
      sideImage1: MobileScreen19,
      sideImage2: MobileScreen15,
      isLeftAlign: true,
    },

    {
      title: "Strengthen your connection and cultivate a positive rapport with your landlord",
      description:
        "Seamlessly communicate your needs and maintain organized conversations. Share images, videos, and documents to convey your message clearly and effectively, unique, you’ll be able to ensure your landlord can easily review and address your requests promptly.",
      sideImage1: MobileScreen17,
      sideImage2: MobileScreen18,
      isLeftAlign: true,
    },
  ],
}
