import React, { useRef } from "react"
import { Container, Button } from "../index"
import { serviceItems } from "../../data/home"
import { motion, useScroll, useTransform } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { useSolution } from "../../context"
import useInViewAnimation from "../../hooks/useInViewAnimation"
import useMediaQuery from "../../hooks/useMediaQuery"

const ServiceItem = ({ serviceItem, isLeftAlign = false }) => {
  const isAboveMediumScreen = useMediaQuery("(min-width:768px)")
  const { toggleSelectedTab } = useSolution() // context
  const navigate = useNavigate()
  const { ref, visible } = useInViewAnimation(0.5)

  const serviceVariant = {
    hidden: {
      opacity: 0,
      y: 80,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.2,
        duration: 1,
        type: "tween",
      },
    },
  }

  const clickHandler = (category) => {
    toggleSelectedTab(category)
    navigate({ pathname: "/solutions", hash: "#solutionTabs" })
  }
  return (
    <motion.div
      ref={ref}
      variants={serviceVariant}
      initial="hidden"
      animate={visible}
      className={`flex flex-col md:flex-row items-start text-left gap-10`}
    >
      {/* Image */}
      <div className={`flex-shrink-0 flex-1 w-full ${isAboveMediumScreen && isLeftAlign ? "order-2" : ""}`}>
        <img className="rounded max-w-[90%] mx-auto" src={serviceItem.image} alt="Manage your entire portfolio" />
      </div>

      <div className="flex-1 flex flex-col pt-4">
        {/* Heading */}
        <h3 className="font-roboto font-bold text-darkGray text-2xl leading-[1]">{serviceItem.title}</h3>

        {/* content */}
        <div className="mt-4">
          <p className="font-roboto text-base text-darkGray mx-auto">{serviceItem.content}</p>
          <Button
            className="px-3 hidden py-2 font-normal font-roboto rounded mt-8 border border-darkBlackgreen w-[110px]"
            textColor="text-[#146C6A]"
            iconSize={15}
            isIcon
            onClick={() => clickHandler(serviceItem.category)}
          >
            Explore
          </Button>
        </div>
      </div>
    </motion.div>
  )
}

const Services = () => {
  const { ref, visible } = useInViewAnimation(0)
  const isAboveLargeScreen = useMediaQuery("(min-width:1280px)")

  const headingVariant = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        type: "tween",
      },
    },
  }
  return (
    // <div className="w-full relative z-30 bg-whiteGradiant py-24">
    <div className={`w-full relative z-30 pt-8 pb-24`}>
      <Container className="grid grid-rows-1 border-b-2 border-b-slate-300 pb-24">
        <motion.h2
          ref={ref}
          variants={headingVariant}
          initial="hidden"
          animate={visible}
          className="text-2xl md:text-4xl leading-[1] font-roboto text-darkGray font-bold place-self-center pb-24"
        >
          Easy, Simple and Advanced
        </motion.h2>
        {/* Services  */}
        <div className="grid grid-cols-1 md:grid-cols-1 gap-x-14 gap-y-[100px]">
          {serviceItems.map((serviceItem, index) => (
            <ServiceItem key={`serviceItem-${index}`} serviceItem={serviceItem} isLeftAlign={(index + 1) % 2 === 0} />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Services
