import React, { useState } from "react"
import { accordionItems } from "../../data/solutions"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"

function AccordianItem({ index, item, section }) {
  const [activeAccordion, setActiveAccordion] = useState(null)

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index)
  }
  return (
    <div className="shadow-md mb-8">
      <button
        type="button"
        className={`flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 gap-3 ${
          activeAccordion !== index ? "hover:bg-gray-100" : ""
        }`}
        onClick={() => toggleAccordion(index)}
        aria-expanded={activeAccordion === index}
        aria-controls={`accordion-collapse-body-${section}-${index + 1}`}
      >
        <span>{item.question}</span>
        {/* {activeAccordion === index && <IoIosArrowDown />}
        {activeAccordion !== index && <IoIosArrowUp />} */}

        <svg
          className={`w-3 h-3 ${
            activeAccordion === index ? "rotate-0" : "rotate-180"
          } shrink-0 transition-transform duration-300`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
        </svg>
      </button>
      <div
        id={`accordion-collapse-body-${section}-${index + 1}`}
        className={`${activeAccordion === index ? "block" : "hidden"} p-5  mb-8`}
      >
        <p className="mb-2 text-gray-500 ">{item.answer}</p>
      </div>
    </div>
  )
}

const Accordion = () => {
  return (
    <div className="w-full ">
      {/* HEADING */}
      <h1 className="text-2xl md:3xl lg:text-4xl leading-[1.2] font-roboto text-center mt-40 mb-16 text-darkGray font-bold">
        Common Questions
      </h1>
      <div className="flex flex-col md:flex-row gap-10">
        <div className="basis-[50%]">
          {accordionItems.firstSection.map((item, index) => (
            <AccordianItem
              key={`accordianItem-firstSection-${index}`}
              index={index}
              item={item}
              section={"firstSection"}
            />
          ))}
        </div>
        <div className="basis-[50%]">
          {accordionItems.secondSection.map((item, index) => (
            <AccordianItem
              key={`accordianItem-secondSection-${index}`}
              index={index}
              item={item}
              section={"secondSection"}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Accordion
