import React from "react"
import { Container } from "../components"

const CookiesPolicy = () => {
  return (
    <div className="relative pb-10">
      <div className="relative bg-privacyHeaderGradiant bg-contain">
        {/* <div className="absolute top-0 bottom-0 w-full h-full bg-privacyHeaderGradiant flex shrink-0" /> */}
        <div className=" h-[400px] ">
          <Container className=" relative">
            <h1 className=" absolute  top-[12rem] text-4xl leading-[1.2] font-roboto text-white font-bold">
              Cookie Policy
            </h1>
          </Container>
        </div>
      </div>
      <Container className="max-w-[69%] bg-white">
        <p className="text-base font-arial pb-6">
          This Cookie Policy explains how Propertise FZCO also known as Propertise and operating
          https://www.propertise.com/ ("we", "us", or "our") uses cookies and similar technologies to recognize you when
          you visit our website at www.propertise.com ("Website"). It explains what these technologies are and why we
          use them, as well as your rights to control our use of them.
        </p>
        <div>
          <p className="text-base font-arial pb-2 font-bold">What are cookies?</p>
          <p className="text-base font-arial pb-6">
            Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in
            your web browser and allows the Website or a third-party to recognize you and make your next visit easier
            and the Website more useful to you.
          </p>
        </div>
        <div>
          <p className="text-base font-arial pb-2 font-bold">How we use cookies</p>
          <p className="text-base font-arial pb-6">
            When you use and access the Website, we may place a number of cookies files in your web browser. We use
            cookies for the following purposes:
          </p>
          <ul className="pb-6 list-disc ml-10">
            <li>To enable certain functions of the Website</li>
            <li>To provide analytics</li>
            <li>To store your preferences</li>
            <li>Mailing address</li>
            <li>To enable advertisements delivery, including behavioral advertising</li>
          </ul>
          <p className="text-base font-arial pb-6">
            We use both session and persistent cookies on the Website and we use different types of cookies to run the
            Website:
          </p>
          <ul className="pb-6 list-disc ml-10">
            <li>
              Essential cookies: We may use essential cookies to authenticate users and prevent fraudulent use of user
              accounts.
            </li>
            <li>
              Analytical/performance cookies: We may use analytical/performance cookies to analyze how users interact
              with the Website and to monitor its performance. This allows us to provide a high-quality experience by
              customizing our offering and quickly identifying and fixing any issues that arise.
            </li>
            <li>Advertising cookies: We may use advertising cookies to deliver relevant advertisements to users.</li>
          </ul>
        </div>

        <div>
          <p className="text-base font-arial pb-2 font-bold">Third-party cookies</p>
          <p className="text-base font-arial pb-6">
            In addition to our own cookies, we may also use various third-party cookies to report usage statistics of
            the Website, deliver advertisements on and through the Website, and so on.
          </p>
        </div>
        <div>
          <p className="text-base font-arial  pb-2  font-bold">Your choices regarding cookies</p>
          <p className="text-base font-arial pb-6">
            If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the
            help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them,
            you might not be able to use all of the features we offer, you may not be able to store your preferences,
            and some of our pages might not display properly.
          </p>
        </div>
        <div>
          <p className="text-base font-arial pb-6 font-bold">Changes to our Cookie Policy</p>
          <p className="text-base font-arial pb-6">
            We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new
            Cookie Policy on this page.
          </p>
        </div>
        <div>
          <p className="text-base font-arial pb-2 font-bold">Contact us</p>
          <p className="text-base font-arial pb-6">
            If you have any questions about our Cookie Policy, please contact us by email on{" "}
            <a className="text-[#0563c1] underline" href="mailto:info@propertise.com">
              info@propertise.com
            </a>
            .
          </p>
          <p className="text-base font-arial pb-6 font-bold">
            This Cookie Policy was last updated in 22 February 2024.
          </p>
        </div>
      </Container>
    </div>
  )
}

export default CookiesPolicy
