import React from "react"
import useInViewAnimation from "../../hooks/useInViewAnimation"
import { motion } from "framer-motion"
import { IPSEvent } from "../../assets/images/images"

const EventCard = ({ isFirst = false, delay = 0 }) => {
  const { ref, visible } = useInViewAnimation(0.5)

  const eventCardVariant = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 1,
        type: "tween",
      },
    },
  }
  return (
    <motion.div ref={ref} variants={eventCardVariant} initial="hidden" animate={visible}>
      <div
        className={`relative  ${
          isFirst
            ? ""
            : "before:absolute before:w-full  before:top-0 before:bottom-0 before:bg-white before:bg-opacity-80  before:backdrop-blur-sm before:z-20"
        }`}
      >
        {/* {!isFirst && (
          <div className="w-full h-[270px] lg:max-w-[335px] md:h-[270px] bg-darkBlackgreen rounded-lg md:overflow-hidden mx-auto md:mx-0 mb-8"></div>
        )} */}

        <img className="w-[335px] rounded-lg mx-auto mb-8  object-contain" src={IPSEvent} alt="IPS Event" />

        <div className="xl:w-[310px] mx-auto mt-6 md:mt-0">
          <h2 className="text-2xl leading-[1] font-roboto text-darkGray font-bold text-left ">
            International Property Show
          </h2>
          <p className="text-lg font-roboto text-left text-darkGray font-bold my-6">
            27<sup>th</sup>-29<sup>th</sup> Feb 2024 – 10AM-6PM World Trade Center, Dubai
          </p>
          <p className="text-lg font-arial text-left text-darkGray mb-6">
            Join us at the 22nd International Property Show as we unravel our solution. Get the chance to meet our
            founder and enquire about Propertise!
          </p>
          <span>
            <a className="underline text-blue-500" href="https://www.ipscongress.com" target="_blank">
              Visit Website
            </a>
          </span>
        </div>
      </div>
    </motion.div>
  )
}

export default EventCard
