import React from "react"
import useInViewAnimation from "../../hooks/useInViewAnimation"
import { motion } from "framer-motion"

const Card = ({ imageSrc, altText, content, delay = 0 }) => {
  const { ref, visible } = useInViewAnimation(0.5)

  const comingSoonCardVariant = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 1,
        type: "tween",
      },
    },
  }

  return (
    <motion.div
      ref={ref}
      variants={comingSoonCardVariant}
      initial="hidden"
      animate={visible}
      className="w-full md:w-[48%] lg:w-[33.33%] mb-10"
    >
      <div className="md:max-w-[335px] text-center mx-auto">
        <img
          className="max-w-[40%] md:max-w-[85%] h-auto inline-block mx-auto object-contain"
          src={imageSrc}
          alt={altText}
        />
      </div>
      <div className="w-full lg:max-w-[310px] mx-auto mt-6 md:mt-0">
        <p className="text-lg font-arial text-center text-darkGray my-10">{content}</p>
      </div>
    </motion.div>
  )
}

export default Card
