import React from "react"
import Container from "./Container"
import { TouchConfirm } from "../../assets/icons/icons"

const FormConfirmation = () => {
  return (
    <div className="w-full ">
      <Container className="flex flex-col items-center justify-center text-center">
        <span>
          <img src={TouchConfirm} alt="Confirmation Icon" className="w-10 h-10" />
        </span>
        <p className="font-roboto font-bold text-3xl w-[40%] border-b pb-4 text-white mt-8">
          Thank you for getting in touch, one of our representatives will be in touch with you shortly.
        </p>
      </Container>
    </div>
  )
}

export default FormConfirmation
