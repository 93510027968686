import React from "react"
import { Container } from "../components"

const PrivacyPolicy = () => {
  return (
    <div className="relative pb-10">
      <div className="relative bg-privacyHeaderGradiant bg-contain">
        {/* <div className="absolute top-0 bottom-0 w-full h-full bg-privacyHeaderGradiant flex shrink-0" /> */}
        <div className=" h-[400px] ">
          <Container className=" relative">
            <h1 className=" absolute  top-[12rem] text-4xl leading-[1.2] font-roboto text-white font-bold">
              Privacy Policy
            </h1>
          </Container>
        </div>
      </div>
      <Container className="max-w-[69%] bg-white">
        <p className="text-base font-arial pb-6">
          Propertise FZCO trading as Propertise and operating https://www.propertise.com/ (we, our or us) is committed
          to protecting the privacy of its users. This Privacy Policy outlines the types of personal information that is
          received and collected by us and how it is used.
        </p>
        <p className="text-base font-arial pb-6">
          We may update this privacy policy from time to time. The most updated version will be available on our website
          (https://www.propertise.com/). If any update would result in a material change to your rights or obligations,
          we will notify you via email prior to it taking effect. By using our website, or by providing any personal
          information to us, you consent to the collection, use and disclosure of your personal information as set out
          in this privacy policy.
        </p>
        <div>
          <p className="text-base font-arial pb-2 font-bold">1. Information We Collect</p>
          <p className="text-base font-arial pb-6">
            We may collect personal information from users in a variety of ways, including, but not limited to, when
            users visit our site, register on the site, fill out a form, and in connection with other activities,
            services, features, or resources we make available on our site.
          </p>
          <p className="text-base font-arial pb-6">
            We may ask you to provide us with information that can be used to contact or identify you (“Personal Data”).
            Such data may include, but is not limited to:
          </p>
          <ul className="pb-6 list-disc ml-10">
            <li>First and Last Name</li>
            <li>Email address</li>
            <li>Mailing address</li>
            <li>Phone or mobile number</li>
          </ul>
          <p className="text-base font-arial pb-6">
            We will collect personal information from users only if they voluntarily submit such information to us.
            Users can always refuse to supply personal information, except that it may prevent them from engaging in
            certain site-related activities.
          </p>
          <p className="text-base font-arial pb-6">
            We will collect personal information from users only if they voluntarily submit such information to us.
            Users can always refuse to supply personal information, except that it may prevent them from engaging in
            certain site-related activities.
          </p>
        </div>
        <div>
          <p className="text-base font-arial  pb-2  font-bold">2. Log Files</p>
          <p className="text-base font-arial pb-6">
            We make use of log files to improve your user experience. The information inside the log files includes
            internet protocol (IP) addresses, type of browser, Internet Service Provider (ISP), date/time stamp,
            referring/exit pages, and the number of clicks to analyze trends, administer the site, track user's movement
            around the site, and gather demographic information.
          </p>
        </div>
        <div>
          <p className="text-base font-arial pb-6 font-bold">3. Cookies and Web Beacons</p>
          <p className="text-base font-arial pb-6">
            We may use cookies to store information about visitors' preferences when you use our site, to record
            user-specific information on which pages the site visitor accesses or visits, and to personalize or
            customize our web page content based on visitors' browser type or other information that the visitor sends
            via their browser.
          </p>
          <p className="text-base font-arial pb-6">
            We may gather certain data through automated methods employing technologies like cookies, web beacons,
            browser analyzing tools, pixels, file logs, and mobile identifiers. Often, the data obtained through cookies
            and similar tools is utilized in a non-identifiable manner, without linking it to personal information. For
            instance, we might utilize this data to enhance our understanding of website traffic and engagement and
            improve the user experience.
          </p>
        </div>
        <div>
          <p className="text-base font-arial pb-2 font-bold">4. DoubleClick DART Cookie</p>
          <ul className="pb-6 list-disc ml-10">
            <li>Google, as a third-party vendor, may use cookies to serve ads on Propertise.</li>
            <li>
              Google's use of the DART cookie enables it to serve ads to users based on their visit to our site and
              other sites on the Internet
            </li>
            <li>
              Users may opt-out of the use of the DART cookie by visiting the Google ad and content network privacy
              policy at the following URL:{" "}
              <a className="text-[#0563c1] underline" href="http://www.google.com/privacy_ads.html ">
                http://www.google.com/privacy_ads.html{" "}
              </a>
            </li>
          </ul>
        </div>
        <div>
          <p className="text-base font-arial pb-2 font-bold">5. Direct Marketing</p>
          <p className="text-base font-arial pb-6">
            From time to time we may use your personal information to provide you with information regarding, but not
            limited to, our company, products, services, real estate, property management, or promotional offers. By
            providing us with your personal information, you consent to receiving such information, through phone,
            email, SMS, and social media channels, until you unsubscribe. If you prefer not to receive marketing
            updates, you can opt out at any time by contacting us using the details provided in the "Contact Us" section
            of this policy.
          </p>
        </div>
        <div>
          <p className="text-base font-arial pb-2 font-bold">6. Legal Obligations </p>
          <p className="text-base font-arial pb-6">
            In response to legal requirements, Propertise might disclose your information, including personal
            information, to courts, law enforcement agencies, governmental authorities, tax authorities, or authorized
            third parties, as we are obligated or permitted by law to do so, or if such disclosure is reasonably
            essential for fulfilling our legal duties and responsibilities and safeguarding the rights and safety of
            Propertise, its staff, users or the public.
          </p>
        </div>
        <div>
          <p className="text-base font-arial pb-2 font-bold">7. Third Party Sites</p>
          <p className="text-base font-arial pb-6">
            Our site may contain links to third party sites maintained and controlled by third parties. Any information
            collected on third party sites will follow the third party site’s privacy policy as they are not controlled
            by us.
          </p>
        </div>
        <div>
          <p className="text-base font-arial pb-2 font-bold">8. Changes to this Privacy Policy</p>
          <p className="text-base font-arial pb-6">
            Propertise has the discretion to update this privacy policy at any time. When we do, we will revise the
            updated date at the bottom of this page. We encourage users to frequently check this page for any changes to
            stay informed about how we are helping to protect the personal information we collect. You acknowledge and
            agree that it is your responsibility to review this privacy policy periodically and become aware of
            modifications.
          </p>
        </div>
        <div>
          <p className="text-base font-arial pb-2 font-bold">9. Contact Us</p>
          <p className="text-base font-arial pb-6">
            If you have any queries about this Privacy Policy or if you would like to request access to your information
            or unsubscribe to our communication list, you can contact us with your request via email on{" "}
            <a className="text-[#0563c1] underline" href="mailto:info@propertise.com">
              info@propertise.com
            </a>
            .
          </p>
          <p className="text-base font-arial pb-6 font-bold">
            This Privacy Policy was last updated in 22 February 2024.
          </p>
        </div>
      </Container>
    </div>
  )
}

export default PrivacyPolicy
