import React, { useId } from "react"
import { useModel } from "../../context"

const Select = (
  { label, type = "text", labelColor = "text-white", className = "", error, containerStyle = "", ...props },
  ref
) => {
  const id = useId()
  const { isModelOpen } = useModel()

  return (
    <div className="flex items-center gap-2">
      <input
        id={id}
        type="checkbox"
        className={`px-3 py-2 rounded bg-transparent text-sm font-arial  outline-none border text-ellipsis ${className}`}
        {...props}
        ref={ref}
      />
      <span>
        {label && (
          <label
            htmlFor={id}
            className={`inline-block  font-bold font-roboto ${
              isModelOpen ? "text-darkGray" : labelColor
            } ${labelColor}`}
          >
            {label}
          </label>
        )}
      </span>
    </div>
  )
}

export default React.forwardRef(Select)
