// CookieConsent.js
import React, { useState } from "react"
import CookieConsent from "react-cookie-consent"
import "./index.css"
import { Link } from "react-router-dom"

const CookieConsentPopup = ({ onAccept }) => {
  const [accepted, setAccepted] = useState(false)

  const handleAccept = () => {
    setAccepted(true)
    onAccept()
  }

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="myCookieConsent"
      style={{
        fontFamily: "Roboto,sans-serif",
        background: "#2B373B",
        width: "75%",
        left: "unset",
        backgroundColor: "#fff",
        color: "#000",
        borderRadius: "8px",
        display: "block",
        padding: "5px 20px",
        textAlign: "center",
      }}
      buttonStyle={{
        color: "#fff",
        fontSize: "13px",
        backgroundColor: "#146C6A",
        width: "150px",
        borderRadius: "5px",
        fontSize: "14px",
        margin: "10px",
      }}
      expires={150}
      onAccept={handleAccept}
    >
      We use cookies to ensure that we give you the best experience on our website. By clicking "Accept", you agree to
      the use of all cookies to enhance site navigation, analyze site usage, and assist in our marketing efforts. For
      more information on how we use cookies and your cookie choices, please see our{" "}
      <Link className="cookie-link" to="/cookie-policy">
        Cookie Policy.
      </Link>
    </CookieConsent>
  )
}

export default CookieConsentPopup
