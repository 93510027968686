import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { IoMdMenu } from "react-icons/io"
import { IoClose } from "react-icons/io5"
import { Button, Container } from "../index"
import { Link } from "react-router-dom"

import * as images from "../../assets/images/images"
import useMediaQuery from "../../hooks/useMediaQuery"
import { useDarkMode, useModel } from "../../context"

const navItems = [
  {
    slug: "/",
    title: "Home",
  },
  {
    slug: "/corporate",
    title: "Corporate Solutions",
  },
  {
    slug: "/landlords",
    title: "Landlords",
  },
  {
    slug: "/tenants",
    title: "Tenants",
  },

  {
    slug: "/coming-soon",
    title: "Coming Soon!",
  },
]

const Navbar = ({ isTopOfPage = false }) => {
  const isAboveMediumScreen = useMediaQuery("(min-width:920px)")
  const [isMenuToggled, setIsMenuToggled] = useState(false)
  const { pathname, hash } = useLocation()
  const navigate = useNavigate()
  const { toggleIsModel } = useModel()
  const { isDarkMode, toggleDarkMode } = useDarkMode()

  useEffect(() => {
    setIsMenuToggled(false)
  }, [pathname])

  return (
    <div className={`fixed w-full m-0 p-0 z-50`}>
      <div className={`flex flex-col justify-center z-50 absolute top-0 w-full`}>
        {!isTopOfPage && <div className="half-blur-effect"></div>}
        <Container className="flex items-center ">
          {/* LOGO */}
          <div className="flex-1 basis-[40%] py-2 h-full">
            <img src={images.whiteLogo} alt="logo" className="w-32 object-cover" onClick={() => navigate("/")} />
          </div>

          {/* NAV ITEMS FOR DESKTOP SCREEN */}
          {isAboveMediumScreen ? (
            <div className="flex-1 basis-[60%]  py-2 h-full hidden md:flex flex-grow gap-2 items-center justify-between">
              {navItems.map((navItem) => (
                <Button
                  className={`px-1 py-1 font-medium font-roboto  ${
                    `${pathname}${hash}` === navItem.slug ? "active" : ""
                  }`}
                  key={`navigation-${navItem.title}`}
                  onClick={() => navigate(`${navItem.slug}`)}
                >
                  <Link>{navItem.title}</Link>
                </Button>
              ))}

              {/* light/dark mode */}
              {/* <div
                onClick={() => toggleDarkMode(!isDarkMode)}
                className="w-[66px] h-[30px] mr-2 rounded-full bg-white  relative before:h-6 before:w-6 before:rounded-full before:bg-darkGreen before:absolute before:top-[12%] before:left-[6%]"
              >
                
              </div> */}

              {/* Login  */}
              {/* <Button
                bgColor="bg-white"
                textColor="text-darkGreen"
                className="px-1 font-medium font-roboto w-[108px] rounded py-2"
                onClick={() => toggleIsModel()}
              >
                Login
              </Button> */}
            </div>
          ) : (
            <div>
              <Button
                className="rounded-full p-1 w-full border-none hover:bg-darkBlackgreen"
                onClick={() => setIsMenuToggled(!isMenuToggled)}
              >
                <IoMdMenu color="white" size="30" />
              </Button>
            </div>
          )}

          {/* NAV FOR MOBILE SCREEN */}
          {!isAboveMediumScreen && isMenuToggled && (
            <div className="fixed top-0 right-0 h-full w-[275px] bg-white ">
              <div className="w-full border-b-2 flex items-center justify-between py-14">
                {/* LOGO AND CLOSE ICON */}
                <Container className="flex flex-row items-center justify-between">
                  <img src={images.greenLogo} className="w-[140px]" />
                  <div className="border-2 rounded-full w-5 h-5 border-darkGreen flex items-center">
                    <Button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                      <IoClose color="#15ac8e" />
                    </Button>
                  </div>
                </Container>
              </div>
              {/* NAV ITEMS */}
              <Container className="mt-5">
                <div className="flex flex-col items-start gap-4">
                  {navItems.map((navItem) => (
                    <Button
                      textColor="text-darkGray"
                      className="w-full text-left font-medium font-roboto"
                      key={`navigation-${navItem.title}`}
                      onClick={() => navigate(`${navItem.slug}`)}
                    >
                      {navItem.title}
                    </Button>
                  ))}

                  {/* Login  */}
                  {/* <div className="self-center mt-5">
                    <Button
                      textColor="text-darkGreen"
                      className="px-1 py-1 font-medium font-roboto border border-darkGreen w-[100px] rounded"
                      onClick={() => toggleIsModel()}
                    >
                      Login
                    </Button>
                  </div> */}
                </div>
              </Container>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default Navbar

// <Button
// className={`px-1 py-1 font-medium font-roboto  ${
//   `${pathname}${hash}` === navItem.slug ? "active" : ""
// }`}
// key={`navigation-${navItem.title}`}
// onClick={() => navigate(`${navItem.slug}`)}
// >
// {navItem.title}
// </Button>
