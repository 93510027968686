import React, { useState, useEffect, useRef } from "react"
import useMediaQuery from "../../hooks/useMediaQuery"
import {
  SolutionItem,
  Container,
  Button,
  Security,
  CustomerSupport,
  Form,
  FormConfirmation,
  HorizentalForm,
  HorizentalFormConfirmation,
  Services,
} from "../index"
import { tabs, tabsData } from "../../data/solutions"
import { useLocation } from "react-router-dom"
import { useSolution } from "../../context"
import axiosIns from "../../plugins/axios"
import ReactGA from "react-ga4"

const textAreaLabelQuestion = "Have a feature in mind that you would like to experience with Propertise?"
const textAreaLabelAnswer = "Share with us your wishlist and we’ll make sure to exceed your expectations!"
const contentFirstParagraph =
  "Register now and seize the opportunity to be among the first to experience the future of property management and elevate your real estate business to new heights!"
const contentSecondParagraph =
  "By registering, you'll gain exclusive access to our pre-launch announcements and enjoy a complimentary 2 months access on your annual corporate subscriptions (*T&Cs apply)."

const Solution = () => {
  const specificSectionRef = useRef(null)
  const location = useLocation()
  const [isVerticalFormSubmittion, setIsVerticalFormSubmittion] = useState(false)
  const [isHorizentalFormSubmittion, setIsHorizentalFormSubmittion] = useState(false)
  const isAboveLargeScreen = useMediaQuery("(min-width:1024px)")
  const { selectedTab, toggleSelectedTab } = useSolution()

  const filterData = Object.entries(tabsData).flatMap(([key, value]) => (key === selectedTab ? [...value] : []))

  // useEffect(() => {
  //   if (location.hash === "#solutionTabs") {
  //     specificSectionRef.current.scrollIntoView()
  //   }
  // }, [location.hash])

  const resetFormSubmittion = () => {
    setTimeout(() => {
      setIsHorizentalFormSubmittion(false)
      setIsVerticalFormSubmittion(false)
    }, 3000)
  }

  const handleSubmit = (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endPoint = "landing_page/contact_us"
        const { featureList, ...rest } = formData
        const list = featureList.map((featureItem) => featureItem.label)
        const {
          status,
          data: { data = {} },
        } = await axiosIns.post(endPoint, {
          ...rest,
          featureList: list,
          userType: [0],
          sourceType: 0,
          campaignName: "Pre-Launched-Event",
        })
        if (status === 200) {
          resolve("Successfully Submitted")
          resetFormSubmittion()
          ReactGA.event({
            category: "Corporate Registration Form",
            action: "corporate_registration_submitted",
          })
        }
      } catch (error) {
        reject(error.message)
      }
    })
  }

  return (
    // <div className="w-full relative pb-[700px] md:pb-[450px]">
    <div className="w-full relative pb-[850px] md:pb-[450px]">
      <div className="bg-simpleBGWithGradiant bg-contain  pt-20 pb-40">
        {isVerticalFormSubmittion ? (
          <FormConfirmation />
        ) : (
          <Form
            isWorkEmail={true}
            setIsVerticalFormSubmittion={setIsVerticalFormSubmittion}
            onSubmit={handleSubmit}
            sideHeading="Interested in our Corporate Solution?"
            contentFirstParagraph={contentFirstParagraph}
            contentSecondParagraph={contentSecondParagraph}
            textAreaLabelQuestion={textAreaLabelQuestion}
            textAreaLabelAnswer={textAreaLabelAnswer}
          />
        )}
      </div>
      <div>
        <Services />
        <Container>
          {isHorizentalFormSubmittion ? (
            <HorizentalFormConfirmation />
          ) : (
            <HorizentalForm
              isWorkEmail={true}
              onSubmit={handleSubmit}
              setIsHorizentalFormSubmittion={setIsHorizentalFormSubmittion}
              textAreaPlaceholder="Your Corporate Wishlist"
            />
          )}
        </Container>
      </div>
    </div>
  )
}

export default Solution

{
  /* HEADING */
}
{
  /* <Container>
<div className="sticky top-0 w-full m-0 p-0 z-50">
  <div className="relative h-[150px]  md:h-[105px] backdrop-blur-md"></div>
  <div className="absolute top-0 w-full">
    <h1
      ref={specificSectionRef}
      className="text-2xl md:3xl lg:text-4xl leading-[1.2] font-roboto text-center pb-5 text-darkGray font-bold"
    >
      Solutions
    </h1>

    <div className="bg-[#80CEC7] w-full flex justify-center  rounded">
      <div className="flex flex-row flex-wrap gap-3 items-center justify-start">
        {tabs.map((tabsItem) => (
          <Button
            textColor="text-darkGray"
            className="w-auto font-normal relative font-roboto py-2 px-2 "
            key={`navigation-${tabsItem.title}`}
            onClick={() => toggleSelectedTab(tabsItem.category)}
          >
            {tabsItem.title}
            {selectedTab === tabsItem.category && (
              <div className="border-b-[3px] border-darkGray h-1 p-3 absolute bottom-[0px] left-0 w-full"></div>
            )}
          </Button>
        ))}
      </div>
    </div>
  </div>
</div>


{["propertyManagement", "tenants", "leasing", "maintenance", "accounting", "erp", "tasks", "vendors"].includes(
  selectedTab
) &&
  filterData.map((tabDataItem, index) => (
    <SolutionItem
      index={index}
      key={`${tabDataItem.category}-${index}`}
      isAboveLargeScreen={isAboveLargeScreen}
      tabDataItem={tabDataItem}
      isImgLeft={[index + 1] % 2 === 0}
    />
  ))}
{selectedTab === "security" && <Security tabsData={tabsData} />}
{selectedTab === "customerSupport" && <CustomerSupport />}
{isHorizentalFormSubmittion ? (
  <HorizentalFormConfirmation />
) : (
  <HorizentalForm
    isWorkEmail={true}
    onSubmit={handleSubmit}
    setIsHorizentalFormSubmittion={setIsHorizentalFormSubmittion}
    textAreaPlaceholder="Your Corporate Wishlist"
  />
)}
</Container> */
}
