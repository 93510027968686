import React, { useState } from "react"
import { set } from "react-hook-form"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

const PhoneNumber = ({
  setValue,
  borderWidth = 1,
  isPhoneNumberFilled = false,
  field,
  borderColor = "rgba(237, 247, 245,0.8)",
  textColor = "white",
  focusBorderColor = "rgba(255,255,255,1)",
  customStyle = "styleWhite",
}) => {
  const [isFocused, setIsFocused] = useState(false)
  function changeHandler(phone, e) {
    let CountryCode = e?.dialCode
    let PhoneNumber = phone.split(CountryCode)[1]
    console.log(e)

    if (!PhoneNumber?.trim()) {
      setValue("phoneNumber", `${parseInt(CountryCode)}`)
    } else {
      setValue("phoneNumber", `${parseInt(CountryCode)} ${PhoneNumber}`)
    }
  }
  return (
    <PhoneInput
      country={"ae"}
      inputClass={customStyle}
      enableSearch
      inputProps={{ field }}
      disableSearchIcon
      searchStyle={{ width: "100%", margin: "0" }}
      placeholder="0000000"
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      inputStyle={{
        fontSize: "14px",
        width: "100%",
        height: "40px",
        backgroundColor: "transparent",
        border: isPhoneNumberFilled
          ? `${borderWidth}px solid ${isFocused ? focusBorderColor : borderColor}`
          : `1px solid ${borderColor}`,
        color: textColor,
      }}
      onChange={field.onChange} // Pass the onChange handler
      // onChange={field.onChange}
      value={field.value} // Bind the value to field.value
    />
  )
}

export default PhoneNumber
