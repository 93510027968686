import React, { useState } from "react"
import { MultiSelect } from "react-multi-select-component"
import { Controller } from "react-hook-form"

function MultiSelectDropdown({
  filledBorderColor = false,
  borderWidth = 1,
  options,
  label,
  field,
  labelColor = "text-white",
  focusBorderColor = "rgba(255,255,255,1)",
  borderColor = "rgba(237, 247, 245,0.8)",
  isVertical = true,
  styleSelect = "lightStyle",
  control,
  filledFeatureList = false,
}) {
  const customValueRenderer = (_selected, _options) => {
    if (_selected.length === _options.length) {
      return "All"
    } else if (_selected.length === 0) {
      return "Choose feature"
    } else {
      return _selected.length === _options.length ? "All" : _selected.map(({ label }) => label + ",")
    }
  }

  const DefaultItemRenderer = ({ checked, option, onClick, disabled }) => (
    <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
      <input type="checkbox" onChange={onClick} checked={checked} tabIndex={-1} disabled={disabled} />
      {option.label === "Select All" ? <span>All</span> : <span>{option.label}</span>}
    </div>
  )

  return (
    <div className="w-full">
      {label && <label className={`inline-block mb-1 pl-1  font-bold font-roboto ${labelColor}`}>{label}</label>}
      <Controller
        name="featureList"
        defaultValue={[]}
        control={control}
        render={({ field: { onChange, value } }) => (
          <MultiSelect
            options={options}
            value={value}
            onChange={onChange}
            labelledBy={"Choose feature"}
            disableSearch={true}
            className={`${styleSelect} ${filledFeatureList && "filledBorder"}`}
            valueRenderer={customValueRenderer}
            ItemRenderer={DefaultItemRenderer}
          />
        )}
      />
    </div>
  )
}

export default MultiSelectDropdown
