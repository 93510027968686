import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4"
import axios from "axios"

const RouteChangeTracker = ({ children }) => {
  const location = useLocation()

  const getUserIPAddress = async () => {
    try {
      const response = await axios.get("https://ipinfo.io")
      const ipAddress = response.data.ip
      console.log("User IP Address:", ipAddress)
      return ipAddress
    } catch (error) {
      console.error("Error fetching IP address:", error)
      return null
    }
  }

  useEffect(() => {
    const path = `${location.pathname + location.search}`
    let formattedPath = path.replace(/^\//, "").charAt(0).toUpperCase() + path.slice(2)

    if (path == "/") formattedPath = "Home"

    // console.log(formattedPath)

    ReactGA.send({ hitType: "pageview", page: path, title: formattedPath })
    // ReactGA.pageview(location.pathname + location.search);
  }, [location])

  return <>{children}</>
}

export default RouteChangeTracker
