import React, { useEffect, useState } from "react"
import { Container, Input, PhoneNumber, MultiSelectDropdown, Button } from "../index"
import { useForm, Controller } from "react-hook-form"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"

const Form = ({
  isWorkEmail = false,
  setIsVerticalFormSubmittion,
  sideHeading = "",
  onSubmit,
  contentFirstParagraph,
  contentSecondParagraph = "",
  textAreaLabelQuestion,
  textAreaLabelAnswer,
}) => {
  const { pathname } = useLocation()
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)
  const hasList = ["/landlords", "/tenants"].includes(pathname)
  const [serviceItems, setServiceItems] = useState([
    { value: "property management", label: "Property Management" },
    { value: "leasing", label: "Leasing" },
    { value: "tenants", label: "Tenants" },
    { value: "maintenance", label: "Maintenance" },
    { value: "accounting", label: "Accounting" },
  ])

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    getFieldState,
    formState: { errors },
  } = useForm({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    message: "",
    featureList: [],
  })
  const submitHandler = (data) => {
    setIsBtnDisabled(true)
    onSubmit(data)
      .then((message) => {
        setIsBtnDisabled(false)
        reset({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          companyName: "",
          message: "",
          featureList: [],
        })
        setIsVerticalFormSubmittion(true)
      })
      .catch((error) => {
        console.log(error)
        setIsBtnDisabled(false)
      })
  }

  const [filledFirstName, setFilledFirstName] = useState(false)
  const [filledLastName, setFilledLastName] = useState(false)
  const [filledEmail, setFilledEmail] = useState(false)
  const [filledPhoneNumber, setFilledPhoneNumber] = useState(false)
  const [filledCompany, setFilledCompany] = useState(false)
  const [filledMessage, setFilledMessage] = useState(false)
  const [filledFeatureList, setFeatureList] = useState(false)
  const [filledCompanyEmail, setFilledCompanyEmail] = useState(false)

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (value.firstName) {
        setFilledFirstName(true)
      } else {
        setFilledFirstName(false)
      }
      if (value.lastName) {
        setFilledLastName(true)
      } else {
        setFilledLastName(false)
      }
      if (value.email) {
        setFilledEmail(true)
      } else {
        setFilledEmail(false)
      }
      if (value.companyEmail) {
        setFilledCompanyEmail(true)
      } else {
        setFilledCompanyEmail(false)
      }
      if (value.phoneNumber) {
        setFilledPhoneNumber(true)
      } else {
        setFilledPhoneNumber(false)
      }
      if (value.companyName) {
        setFilledCompany(true)
      } else {
        setFilledCompany(false)
      }
      if (value.message) {
        setFilledMessage(true)
      } else {
        setFilledMessage(false)
      }
      if (value?.featureList?.length !== 0) {
        setFeatureList(true)
      } else {
        setFeatureList(false)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <Container>
      {/* <div className="w-full flex flex-col lg:flex-row gap-12 mt-6 mb-40 h-full "> */}
      <div className="w-full flex flex-col lg:flex-row gap-12  h-full ">
        <div className="basis-[40%]">
          <h1 className="text-2xl sm:text-4xl  md:3xl leading-[1] font-roboto text-white font-bold">{sideHeading}</h1>
          <p className="mt-5 text-[17px] text-white font-arial">{contentFirstParagraph}</p>
          {contentSecondParagraph && <p className="mt-4 text-[17px] text-white font-arial">{contentSecondParagraph}</p>}
        </div>
        <div className="basis-[60%]">
          <form onSubmit={handleSubmit(submitHandler)}>
            <h1 className="text-2xl md:3xl lg:text-4xl leading-[0] font-roboto pb-[3rem] text-white font-bold">
              Register Your Interest
            </h1>
            <div className="flex flex-col sm:flex-row gap-10">
              <div className="w-full lg:w-1/2 flex-1">
                {/* FIRST NAME */}
                <div className="mb-4 w-full">
                  <Input
                    name="firstName"
                    label="First Name"
                    type="text"
                    placeholder="Enter your first name"
                    className={`text-white placeholder:text-extraLightGreenColor border-extraLightGreenColor  focus:border-white  hover:border-white border-opacity-80  focus:placeholder:text-white ${
                      filledFirstName
                        ? "border-white border-opacity-100 border-[2.8px] "
                        : "border-extraLightGreenColor border-opacity-80"
                    }`}
                    autoComplete="off"
                    {...register("firstName", {
                      required: true,
                    })}
                  />
                  {errors.firstName?.type === "required" && (
                    <p className="text-red-600 ml-2 text-sm">First name is required</p>
                  )}
                </div>
                {/*LAST NAME */}
                <div className="mb-4 w-full">
                  <Input
                    name="lastName"
                    label="Last Name"
                    type="text"
                    placeholder="Enter your last name"
                    className={`text-white placeholder:text-extraLightGreenColor border-extraLightGreenColor focus:border-white border-opacity-80 hover:border-white  focus:placeholder:text-white ${
                      filledLastName
                        ? "border-white border-opacity-100 border-[2.8px]"
                        : "border-extraLightGreenColor border-opacity-80"
                    }`}
                    autoComplete="off"
                    {...register("lastName", {
                      required: true,
                    })}
                  />
                  {errors.lastName?.type === "required" && (
                    <p className="text-red-600 ml-2 text-sm">Last name is required</p>
                  )}
                </div>
                {/* EMAIL */}
                {!isWorkEmail && (
                  <div className="mb-4 w-full">
                    <Input
                      name="email"
                      label="Email"
                      type="text"
                      placeholder="Enter your email"
                      className={`text-white placeholder:text-extraLightGreenColor border-extraLightGreenColor focus:border-white border-opacity-80 hover:border-white  focus:placeholder:text-white ${
                        filledEmail
                          ? "border-white border-opacity-100 border-[2.8px]"
                          : "border-extraLightGreenColor border-opacity-80"
                      }`}
                      autoComplete="off"
                      {...register("email", {
                        required: true,
                        validate: {
                          matchPattern: (value) =>
                            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                              value
                            ) || "Not a valid email",
                        },
                      })}
                    />
                    {errors.email?.type === "required" && (
                      <p className="text-red-600 ml-2 text-sm">Email is required</p>
                    )}
                    {errors.email?.type === "matchPattern" && (
                      <p className="text-red-600 ml-2 text-sm">{errors.email?.message}</p>
                    )}
                  </div>
                )}
                {isWorkEmail && (
                  <div className=" mb-4  w-full">
                    <Input
                      name="companyEmail"
                      label="Work Email"
                      type="text"
                      placeholder="Enter your email"
                      labelColor="text-white"
                      className={`text-white placeholder:text-extraLightGreenColor border-extraLightGreenColor focus:border-white border-opacity-80 hover:border-white  focus:placeholder:text-white ${
                        filledCompanyEmail
                          ? "border-white border-opacity-100 border-[2.8px]"
                          : "border-extraLightGreenColor border-opacity-80"
                      }`}
                      autoComplete="off"
                      {...register("companyEmail", {
                        required: true,
                        validate: {
                          matchPattern: (value) =>
                            /^(?!.*@(?:gmail|yahoo|hotmail|outlook|aol|icloud|live|msn|facebook|twitter|instagram)\.com)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g.test(
                              value
                            ) || "Not a valid email",
                        },
                      })}
                    />
                    {errors.companyEmail?.type === "required" && (
                      <p className="text-red-600 ml-2 text-sm">Work Email is required</p>
                    )}
                    {errors.companyEmail?.type === "matchPattern" && (
                      <p className="text-red-600 ml-2 text-sm">{errors.companyEmail?.message}</p>
                    )}
                  </div>
                )}

                {/* PHONE NO */}
                <div className="mb-4 w-full">
                  <label className="inline-block mb-1 pl-1 text-white font-bold font-roboto">Mobile</label>
                  <Controller
                    name="phoneNumber"
                    defaultValue=""
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <PhoneNumber
                        setValue={setValue}
                        field={field}
                        borderColor={`${filledPhoneNumber ? "rgba(255,255,255,1)" : "rgba(237, 247, 245,0.8)"}`}
                        borderWidth={2.8}
                        isPhoneNumberFilled={filledPhoneNumber}
                        customStyle={filledPhoneNumber ? "styleWhiteBold" : "styleWhite"}
                      />
                    )}
                  ></Controller>
                  {errors.phoneNumber?.type === "required" && (
                    <p className="text-red-600 ml-2 text-sm">Mobile number is required</p>
                  )}
                </div>

                {/* COMPANY NAME */}
                {!["/landlords", "/tenants"].includes(pathname) && (
                  <div className="mb-4 w-full">
                    <Input
                      name="companyName"
                      label="Company"
                      type="text"
                      placeholder="Enter company name"
                      className={`text-white placeholder:text-extraLightGreenColor border-extraLightGreenColor focus:border-white border-opacity-80 hover:border-white  focus:placeholder:text-white ${
                        filledCompany
                          ? "border-white border-opacity-100 border-[2.8px] "
                          : "border-extraLightGreenColor border-opacity-80"
                      }`}
                      autoComplete="off"
                      {...register("companyName", { required: true })}
                    />
                    {errors.companyName?.type === "required" && (
                      <p className="text-red-600 ml-2 text-sm">Company name is required</p>
                    )}
                  </div>
                )}
              </div>
              <div className="w-full lg:w-1/2 flex-1">
                {!hasList && (
                  <div className="w-full">
                    <MultiSelectDropdown
                      control={control}
                      options={serviceItems}
                      label="Which features are you interested in?"
                      filledBorderColor={filledFeatureList}
                      borderWidth={2.8}
                      filledFeatureList={filledFeatureList}
                    />
                  </div>
                )}

                {/* COMPANY NAME */}
                <div className="mb-4 mt-4 w-full">
                  <label htmlFor="message" className="inline-block mb-1 pl-1 text-white  font-arial">
                    <p className="mb-4">{textAreaLabelQuestion}</p>
                    <p>{textAreaLabelAnswer}</p>
                  </label>
                  <textarea
                    placeholder="Share your wishlist with us, and we'll ensure that Propertise exceeds your expectations…"
                    id="message"
                    name="message"
                    rows={7}
                    autoComplete="off"
                    className={`px-3 py-2 rounded w-full bg-transparent border text-white placeholder:text-extraLightGreenColor placeholder:text-opacity-80  border-extraLightGreenColor hover:border-white focus:border-white border-opacity-80  focus:placeholder:text-white outline-none font-arial text-sm resize-none ${
                      filledMessage
                        ? "border-white border-opacity-100 border-[2.8px]"
                        : "border-extraLightGreenColor border-opacity-80"
                    }`}
                    {...register("message")}
                  ></textarea>
                  {errors.message?.type === "required" && (
                    <p className="text-red-600 ml-2 text-sm">Message is required</p>
                  )}
                </div>
                <div className="py-3 font-arial text-sm text-white">
                  *By providing your personal information and clicking register, you agree to our{" "}
                  <Link className="underline" to="/privacy-policy">
                    privacy policy
                  </Link>
                </div>
                <div className="className mt-6 w-full ">
                  <Button
                    disabled={isBtnDisabled}
                    textColor={isBtnDisabled ? "text-white text-opacity-50" : "text-white"}
                    bgColor={isBtnDisabled ? "bg-darkBlackgreen bg-opacity-50" : "bg-darkBlackgreen "}
                    className="w-full py-2 rounded font-roboto text-base"
                    type="submit"
                  >
                    Register
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Container>
  )
}

export default Form
