import React, { useState } from "react"
import {
  Form,
  FormConfirmation,
  HorizentalForm,
  HorizentalFormConfirmation,
  Container,
  ComingSoonCard,
} from "../components"
import axiosIns from "../plugins/axios"
import { MobileScreen4, MobileScreen6, MobileScreen7 } from "../assets/images/images"
import { motion } from "framer-motion"
import useInViewAnimation from "../hooks/useInViewAnimation"
import ReactGA from "react-ga4"
import useMediaQuery from "../hooks/useMediaQuery"

const textAreaLabelQuestion = "Have a feature in mind that you would like to experience with Propertise?"
const textAreaLabelAnswer = "Share with us your wishlist and we’ll make sure to exceed your expectations!"
const contentFirstParagraph =
  "Register now to be among the first to download and utilize Propertise - your ultimate solution for a seamless landlord experience."

const Landlords = () => {
  const isBelowExtraSmallScreen = useMediaQuery("(max-width:410px)")
  const [isVerticalFormSubmittion, setIsVerticalFormSubmittion] = useState(false)
  const [isHorizentalFormSubmittion, setIsHorizentalFormSubmittion] = useState(false)
  const { ref, visible } = useInViewAnimation(0.5)

  const imageVariant = {
    hidden: {
      opacity: 0,
      x: isBelowExtraSmallScreen ? 0 : 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: 1,
      },
    },
  }
  const sideTextVariant = {
    hidden: {
      opacity: 0,
      x: isBelowExtraSmallScreen ? 0 : -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: 1,
      },
    },
  }

  const resetFormSubmittion = () => {
    setTimeout(() => {
      setIsVerticalFormSubmittion(false)
      setIsHorizentalFormSubmittion(false)
    }, 3000)
  }
  const handleSubmit = (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endPoint = "landing_page/contact_us"
        const { featureList, ...rest } = formData

        const {
          status,
          data: { data = {} },
        } = await axiosIns.post(endPoint, {
          ...rest,

          userType: [1],
          sourceType: 0,
          campaignName: "Pre-Launched-Event",
        })
        if (status === 200) {
          resolve("Successfully Submitted")
          resetFormSubmittion()
          ReactGA.event({
            category: "Landlord Registration Form",
            action: "landlord_form_submitted",
          })
        }
      } catch (error) {
        reject(error.message)
      }
    })
  }

  return (
    <div className="w-full">
      <div className="relative  fullBackground">
        <div className="absolute top-0 bottom-0 -z-0 w-full h-full bg-bottomWhiteGradiant" />
        <div className="relative py-24">
          {isVerticalFormSubmittion ? (
            <FormConfirmation />
          ) : (
            <Form
              onSubmit={handleSubmit}
              sideHeading="Interested in Propertise for Landlords?"
              contentFirstParagraph={contentFirstParagraph}
              textAreaLabelQuestion={textAreaLabelQuestion}
              textAreaLabelAnswer={textAreaLabelAnswer}
              setIsVerticalFormSubmittion={setIsVerticalFormSubmittion}
            />
          )}
        </div>
        <div className="relative">
          <div className="flex h-full relative items-stretch overflow-hidden">
            <div className="basis-[50%] min-h-[100%] ml-[5%] sm:ml-[6%] md:ml-[10%] py-40 sm:py-48 md:py-80 relative lg:py-[400px]">
              <motion.div
                ref={ref}
                variants={sideTextVariant}
                initial="hidden"
                animate={visible}
                className="absolute top-4 md:top-20 w-full sm:w-[90%] md:w-[85%] lg:w-[80%] "
              >
                <h1 className="text-2xl sm:text-[36px] md:text-[42px] leading-[1.2] font-roboto text-white font-bold pt-4">
                  Transform Your Landlord Experience with Propertise!
                </h1>
              </motion.div>
            </div>

            <motion.div
              className="basis-[50%] relative min-h-[100%] z-20 "
              ref={ref}
              variants={imageVariant}
              initial="hidden"
              animate={visible}
            >
              <div className="handImgLandlord absolute w-full min-h-[100%]  top-0 right-0 bottom-0"></div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="relative bg-white  py-10 z-0">
        <h1 className="text-3xl leading-[1.2] font-roboto text-center  text-darkGray font-bold pt-4">Coming Soon!</h1>

        <Container className="my-16">
          <div className="w-full flex flex-wrap md:flex-nowrap gap-5 justify-center lg:justify-between ">
            <ComingSoonCard
              delay={0.5}
              imageSrc={MobileScreen6}
              altText="Mobile Screen 6"
              content="Whether you own one or multiple properties, with Propertise, your real estate portfolio is just a tap away, empowering you to stay connected and manage them with ease, anytime, anywhere."
            />
            <ComingSoonCard
              delay={1}
              imageSrc={MobileScreen4}
              altText="Mobile Screen 4"
              content="Don't leave occupancy to chance – take control with Propertise and have real-time insights into your occupancy levels allowing you to optimize your leasing strategy whichever turn the market takes."
            />
            <ComingSoonCard
              delay={1.5}
              imageSrc={MobileScreen7}
              altText="Mobile Screen 7"
              content="Say hello to stress-free leasing with Propertise – where managing your leases has never been easier. Experience the freedom of flexible lease management and simplify your tasks while enhancing your landlord-tenant relationships."
            />
          </div>
        </Container>
      </div>
      <div className="relative py-20 pt-[600px] md:pt-[250px]">
        {isHorizentalFormSubmittion ? (
          <HorizentalFormConfirmation />
        ) : (
          <HorizentalForm
            onSubmit={handleSubmit}
            setIsHorizentalFormSubmittion={setIsHorizentalFormSubmittion}
            textAreaPlaceholder="Your Landlords Wishlist"
          />
        )}
      </div>
    </div>
  )
}

export default Landlords
