import React, { useRef } from "react"
import { Container, Button } from "../index"
import { HeroImg1, HeroImg2 } from "../../assets/images/images"
import doubleImg from "../../assets/images/global/doubleImg.png"
import zero from "../../assets/images/home/0.png"
import { useNavigate } from "react-router-dom"
import useMediaQuery from "../../hooks/useMediaQuery"
import { motion } from "framer-motion"

const Hero = ({ scrollToRegisterForm = () => {} }) => {
  const navigation = useNavigate()
  // const isAboveLargeScreen = useMediaQuery("(min-width:900px)")
  const isAboveMediumScreen = useMediaQuery("(min-width:900px)")
  const ref = useRef(null)

  const heroImageVariant = {
    hidden: {
      opacity: 0,
      x: 200,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: 1,
      },
    },
  }
  const sideTextVariant = {
    hidden: {
      opacity: 0,
      x: -200,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: 1.5,
      },
    },
  }

  return (
    <div className="w-full pb-20 z-0 pt-36  relative ">
      {/* {isAboveLargeScreen && (
        <video className="absolute top-0 z-0" autoPlay muted loop width="100%">
          <source src="https://propertise-web-portal.s3.me-south-1.amazonaws.com/Propertise+Video+2.mp4" />
        </video>
      )} */}
      {isAboveMediumScreen && (
        <video className="absolute top-0 z-0 bottom-0 w-full object-cover " autoPlay muted loop width="100%">
          <source src="https://propertise-web-portal.s3.me-south-1.amazonaws.com/Propertise+Video+2.mp4" />
        </video>
      )}
      <Container className="flex flex-col gap-5 md:flex-row relative ">
        <div className="basis-[50%] overflow-hidden">
          <motion.div variants={sideTextVariant} initial="hidden" animate="visible" className="w-[90%]">
            <h1 className="text-2xl md:3xl lg:text-4xl leading-[1.2] font-roboto text-white font-medium">
              A New Generation Solution to Manage All Property Needs for Real Estate Companies, Landlords and Tenants
            </h1>
            <p className="font-roboto text-xl font-bold text-white my-5 lg:my-8">Coming Soon in UAE!</p>
            <Button
              type="button"
              className="px-6 py-2 font-medium font-roboto rounded w-full sm:w-[120px]"
              bgColor="bg-[#146C6A]"
              onClick={scrollToRegisterForm}
            >
              Register
            </Button>
            <p className="font-roboto text-[18px] text-white font-normal my-5 lg:my-8">
              Register your interest now to get access to our pre-launch announcements and a 2 month free access on your
              annual corporate subscriptions (*T&Cs apply).
            </p>
            <p className="font-roboto text-[18px] text-white font-normal my-5 lg:my-8 ">
              Go ahead and prioritise your property today!
            </p>
          </motion.div>
        </div>
        <div className="basis-[50%] rounded overflow-hidden">
          <motion.img
            ref={ref}
            variants={heroImageVariant}
            initial="hidden"
            animate="visible"
            src={HeroImg1}
            alt="dashboardLight"
            className="w-full rounded "
          ></motion.img>
          {/* <motion.img
            ref={ref}
            variants={heroImageVariant}
            initial="hidden"
            animate="visible"
            src={HeroImg2}
            alt="dashboardLight"
            className="w-full rounded"
          ></motion.img> */}
          {/* <img src={lightDashboard} alt="dashboardLight" className="w-full rounded shadow-imageShadow" /> */}
        </div>
      </Container>
    </div>
  )
}

export default Hero
