import React, { useEffect, useState } from "react"
import axiosIns from "../plugins/axios"
import ReactGA from "react-ga4"
import { MobileScreen16, MobileScreen20, MobileScreen21 } from "../assets/images/images"
import { motion } from "framer-motion"
import useInViewAnimation from "../hooks/useInViewAnimation"
import useMediaQuery from "../hooks/useMediaQuery"

import {
  Form,
  FormConfirmation,
  HorizentalForm,
  HorizentalFormConfirmation,
  Container,
  ComingSoonCard,
} from "../components"

const textAreaLabelQuestion = "Have a feature in mind that you would like to experience with Propertise?"
const textAreaLabelAnswer = "Share with us your wishlist and we’ll make sure to exceed your expectations!"
const contentFirstParagraph =
  "Register now to be among the first to download and utilize Propertise - your ultimate solution for a seamless tenant experience."

const Tenants = () => {
  const isBelowExtraSmallScreen = useMediaQuery("(max-width:410px)")
  const [isVerticalFormSubmittion, setIsVerticalFormSubmittion] = useState(false)
  const [isHorizentalFormSubmittion, setIsHorizentalFormSubmittion] = useState(false)

  const { ref, visible } = useInViewAnimation(0.5)

  const imageVariant = {
    hidden: {
      opacity: 0,
      x: isBelowExtraSmallScreen ? 0 : 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: 1,
      },
    },
  }
  const sideTextVariant = {
    hidden: {
      opacity: 0,
      x: isBelowExtraSmallScreen ? 0 : -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: 1,
      },
    },
  }

  const resetFormSubmittion = () => {
    setTimeout(() => {
      setIsVerticalFormSubmittion(false)
      setIsHorizentalFormSubmittion(false)
    }, 3000)
  }

  const handleSubmit = (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endPoint = "landing_page/contact_us"
        const { featureList, ...rest } = formData
        const {
          status,
          data: { data = {} },
        } = await axiosIns.post(endPoint, {
          ...rest,
          userType: [2],
          sourceType: 0,
          campaignName: "Pre-Launched-Event",
        })
        if (status === 200) {
          resolve("Successfully Submitted")
          ReactGA.event({
            category: "Tenants Registration Form",
            action: "tenant_form_submitted",
          })
          resetFormSubmittion()
        }
      } catch (error) {
        reject(error.message)
      }
    })
  }

  return (
    <div className="w-full">
      <div className="relative  fullBackground">
        <div className="absolute top-0 bottom-0 -z-0 w-full h-full bg-bottomWhiteGradiant" />
        <div className="relative py-24">
          {/* <div className="landlordBanner1 absolute -z-10 top-0 bottom-0"></div> */}
          {isVerticalFormSubmittion ? (
            <FormConfirmation />
          ) : (
            <Form
              onSubmit={handleSubmit}
              sideHeading="Interested in Propertise for Tenants?"
              contentFirstParagraph={contentFirstParagraph}
              textAreaLabelQuestion={textAreaLabelQuestion}
              textAreaLabelAnswer={textAreaLabelAnswer}
              setIsVerticalFormSubmittion={setIsVerticalFormSubmittion}
            />
          )}
        </div>

        <div className="relative">
          <div className="flex relative items-stretch overflow-hidden">
            <div className="basis-[50%] min-h-[100%] ml-[5%] sm:ml-[6%] md:ml-[10%] py-40 sm:py-48 md:py-80 lg:py-[400px] relative ">
              <motion.div
                ref={ref}
                variants={sideTextVariant}
                initial="hidden"
                animate={visible}
                className="absolute  top-4 md:top-20 w-full sm:w-[90%] md:w-[85%] lg:w-[80%] z-30 "
              >
                <h1 className="text-2xl sm:text-[36px] md:text-[42px] leading-[1.2] font-roboto text-white font-bold pt-4">
                  Being A Tenant Has Never Been So Convenient!
                </h1>
              </motion.div>
            </div>

            <motion.div
              ref={ref}
              variants={imageVariant}
              initial="hidden"
              animate={visible}
              className="basis-[50%] relative z-30 min-h-[100%] overflow-hidden "
            >
              <div className="handImgTenants absolute w-full min-h-[100%] overflow-visible top-0 right-0 bottom-0"></div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="relative bg-white  py-10 z-0">
        <h1 className="text-3xl leading-[1.2] font-roboto text-center  text-darkGray font-bold pt-4">Coming Soon!</h1>

        <Container className="my-16">
          <div className="w-full flex flex-wrap md:flex-nowrap gap-5 justify-center lg:justify-between ">
            <ComingSoonCard
              delay={0.5}
              imageSrc={MobileScreen16}
              altText="Mobile Screen 16"
              content="Say goodbye to hassle and hello to convenience with Propertise – because your leasing journey should be smooth sailing from start to finish!"
            />
            <ComingSoonCard
              delay={1}
              imageSrc={MobileScreen20}
              altText="Mobile Screen 20"
              content="When it comes to managing requests, simplicity is key. With Propertise, managing your requests is as effortless as a few clicks, ensuring you receive the clear outcomes you need."
            />
            <ComingSoonCard
              delay={1.5}
              imageSrc={MobileScreen21}
              altText="Mobile Screen 21"
              content="Empower yourself to shape your renting journey with Propertise! Rate your tenant experience and provide valuable feedback to your landlord, ensuring clarity and transparency every step of the way."
            />
          </div>
        </Container>
      </div>
      <div className="relative py-20 pt-[600px] md:pt-[250px]">
        {isHorizentalFormSubmittion ? (
          <HorizentalFormConfirmation />
        ) : (
          <HorizentalForm
            onSubmit={handleSubmit}
            setIsHorizentalFormSubmittion={setIsHorizentalFormSubmittion}
            textAreaPlaceholder="Your Tenants Wishlist"
          />
        )}
      </div>
    </div>
  )
}

export default Tenants
