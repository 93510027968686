import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

import Home from "./pages/Home"
import Solutions from "./pages/Solutions"
import Contact from "./pages/Contact"
import ComingSoon from "./pages/ComingSoon"
import Landlords from "./pages/Landlords"
import Tenants from "./pages/Tenants"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import CookiePolicy from "./pages/CookiePolicy"
import ReactGA from "react-ga4"
import RouteChangeTracker from "./RouteChangeTracker"

ReactGA.initialize("G-N6CEFLD65N")

const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <RouteChangeTracker>
            <Home />
          </RouteChangeTracker>
        ),
      },
      {
        path: "/corporate",
        element: (
          <RouteChangeTracker>
            <Solutions />
          </RouteChangeTracker>
        ),
      },
      {
        path: "/landlords",
        element: (
          <RouteChangeTracker>
            <Landlords />
          </RouteChangeTracker>
        ),
      },
      {
        path: "/tenants",
        element: (
          <RouteChangeTracker>
            <Tenants />
          </RouteChangeTracker>
        ),
      },
      {
        path: "/coming-soon",
        element: (
          <RouteChangeTracker>
            <ComingSoon />
          </RouteChangeTracker>
        ),
      },
      {
        path: "/privacy-policy",
        element: (
          <RouteChangeTracker>
            <PrivacyPolicy />
          </RouteChangeTracker>
        ),
      },
      {
        path: "/cookie-policy",
        element: (
          <RouteChangeTracker>
            <CookiePolicy />
          </RouteChangeTracker>
        ),
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <RouterProvider router={routes}>
      <App />
    </RouterProvider>
  </React.StrictMode>
)
