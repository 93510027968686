import {
  PropertyManagementImg1,
  PropertyManagementImg2,
  PropertyManagementImg3,
  PropertyManagementImg4,
  PropertyManagementImg5,
  PropertyManagementImg6,
  PropertyManagementImg7,
  PropertyManagementImg8,
  PropertyManagementImg9,
  PropertyManagementImg10,
  PropertyManagementImg11,
  PropertyManagementImg12,
  PropertyManagementImg13,
  PropertyManagementImg14,
  PropertyManagementImg15,
  PropertyManagementImg16,
  PropertyManagementImg17,
  PropertyManagementImg18,
  PropertyManagementImg19,
  PropertyManagementImg20,
  PropertyManagementImg21,
  PropertyManagementImg22,
  PropertyManagementImg23,
  PropertyManagementImg24,
  PropertyManagementImg25,
} from "../../assets/images/images"

import {
  SecurityIcon1,
  SecurityIcon2,
  SecurityIcon3,
  SecurityIcon4,
  SecurityIcon5,
  SecurityIcon6,
  SecurityIcon7,
  SecurityIcon8,
  SecurityIcon9,
  SecurityIcon10,
  SecurityIcon11,
  SecurityIcon12,
  SecurityIcon13,
  SecurityIcon14,
  SecurityIcon1Dark,
  SecurityIcon2Dark,
  SecurityIcon3Dark,
  SecurityIcon4Dark,
  SecurityIcon5Dark,
  SecurityIcon6Dark,
  SecurityIcon7Dark,
  SecurityIcon8Dark,
  SecurityIcon9Dark,
  SecurityIcon10Dark,
  SecurityIcon11Dark,
  SecurityIcon12Dark,
  SecurityIcon13Dark,
  SecurityIcon14Dark,
} from "../../assets/icons/icons"

import { CUIcon1, CUIcon2, CUIcon3 } from "../../assets/icons/icons"

// SOLUTION PAGE TABS DATA
export const tabsData = {
  propertyManagement: [
    {
      category: "propertyManagement",
      image: PropertyManagementImg1,
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      title: "One view to your entire portfoliobility",
      content:
        "Whether you're managing a single unit, building or an entire community, view and manage your portfolio effortlessly on one platform, view important stats and have easy access to key actions that allow you to take full control over your property operations, financials and many more, allowing you to maximize your potential!",
    },
    {
      category: "propertyManagement",
      image: PropertyManagementImg2,
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      title: "Migrate your data quickly and effectively",
      content:
        "Save weeks or even months of effort in data migration, with a few simple steps you can have your property data migrated and connected on Properties, getting you up and running in no time!",
    },
    {
      category: "propertyManagement",
      image: PropertyManagementImg3,
      isDoubleSection: false,
      isSubContent: true,
      isHaveList: false,
      isHaveContent: true,
      title: "Property insights and summaries and reports",
      content:
        "You can now monitor your properties' performance anywhere anytime! With Propertise's in-built dashboards, you can have access to a range of key insights that link all your important property data, to keep you informed and up to date with important aspects on your portfolio. You can ensure your business is running smoothly while you focus on everything else that matters.",
      subContent: "Better insights, better actions!",
    },
    {
      category: "propertyManagement",
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      image: PropertyManagementImg4,
      title: "Assign property managers",
      content:
        "You can easily assign your property managers to each property and streamline and delegate work orders, allowing them meet all property requirements, achieve their targets while creating a memorable tenant experience.",
    },
    {
      category: "propertyManagement",
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      image: PropertyManagementImg5,
      title: "Property Rating",
      content:
        "We all strive for higher ratings; simply put, they reflect our commitment and dedication to clients and the overall business. Now you can track and monitor your properties ratings through your tenant's eyes. Making it easier for you to improve and exceed expectations by quickly identifying and resolving root issues.",
    },
  ],
  leasing: [
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "leasing",
      image: PropertyManagementImg6,
      title: "Manage your lease contracts from one place",
      content:
        "Always be up to date with all your residential or commercial leasing contracts. Receive notifications on contracts that are up for renewal. Automate notices and secure tenancy renewals in advance.",
    },
    {
      isDoubleSection: true,
      isSubContent: true,
      isHaveList: false,
      isHaveContent: true,
      category: "leasing",
      image: PropertyManagementImg7,
      title: "Generate all your residential and commercial lease contracts",
      content:
        "Generate standard or custom leases and upload all contracts, tenant credentials and any other relevant documents and ensure you never look for them twice.",
      secondSection: {
        title: "Set up payment plans and schedule payment reminders",
        content:
          "Set your payment plan to reflect the frequency of each payment and schedule payment reminders that notify your tenants on any upcoming or overdue payments and auto invoice your tenants.",
        subContent:
          "Generate multiple tenancy offers to your tenants and negotiate your tenancy financials and payment terms swiftly.",
      },
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "leasing",
      image: PropertyManagementImg8,
      title: "Collect your rent on time with a diverse set of payment options",
      content:
        "Select from a range of payment methods to receive your rent and always have the option to allow your tenants to pay their rent using their credit card without the need to set up your own online payment system, saving you time and cost to offer this option to your tenant base.",
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "leasing",
      image: PropertyManagementImg9,
      title: "Keep your occupancy rates up!",
      content:
        "Always be up to date with your occupancy rates and meet your targets effectively by understanding the underlying causes that lead to higher and lower occupancy rates.",
      subContent:
        "You can easily narrow down the properties that are exposed to lower occupancy rates and take the required actions in advance to maintain and increase occupancy.",
    },
  ],
  tenants: [
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: true,
      isHaveContent: false,
      category: "tenants",
      image: PropertyManagementImg10,
      title: "Connect with all your tenants conveniently and easily",
      list: [
        {
          listTitle: "One integrated app under your control, onboard all your tenants and allow them to:",
          listItems: [
            "Request to move in",
            "Request to move out",
            "Request maintenance",
            "Renew Tenancies",
            "Terminate Tenancies",
            "Pay their rent",
            "Upload documents",
          ],
          listItemSubContent: "And Properties will do the rest.",
        },
      ],
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "tenants",
      image: PropertyManagementImg11,
      title: "Schedule and send payment reminders",
      content:
        "Receive payments from your tenants with a range of payment methods including credit cards without the need to set up your own online payment system, saving you time and effort. Start receiving your money immediately.",
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "tenants",
      image: PropertyManagementImg12,
      title: "Quick and centralized communications",
      content:
        "Say goodbye to scattered conversations and make every conversation with your tenants easy, simple and unique using an in-built centralized communication platform that allows you to conveniently and effectively manage your conversations.",
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "tenants",
      image: PropertyManagementImg13,
      title: "Keep your tenants satisfied, increase your retention and reduce your turnover",
      content:
        "Avoid higher turnover rates by capturing and tracking your tenant satisfaction scores and gain confidence in securing tenancy renewals by better understanding how to retain your tenants through valuable insights.",
    },
  ],
  maintenance: [
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "maintenance",
      image: PropertyManagementImg14,
      title: "Outsource or in-house your maintenance requests swiftly",
      content:
        "Whether you have your own facility management and maintenance team or a third party service provider you can initiate and delegate maintenance requests swiftly and effectively.",
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      image: PropertyManagementImg15,
      category: "maintenance",
      title: "Manage and delegate maintenance tasks effectively",
      content:
        "Manage your team effectively by assigning or re-assigning your team to overlook and handle maintenance requests effectively even when priorities and requirements change.",
    },
    {
      isDoubleSection: true,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "maintenance",
      image: PropertyManagementImg16,
      title: "Keep track of your maintenance costs without going through tons of invoices",
      content:
        "Link expenses to every maintenance job and keep track of your operational running costs on a unit level, development level or even your entire portfolio in no- click! Allowing you to allocate the right budget where it is required flexibly and close your financial year successfully.",
      secondSection: {
        title: "Charge Tenants with ease",
        content: "With one click, you can charge your tenants on any maintenance fee.",
      },
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "maintenance",
      image: PropertyManagementImg17,
      title: "Create and customize your services library and link them directly to maintenance requests",
      content:
        "Create and manage your own custom library of services and link them directly to your next maintenance requests and ensure requests and requirements are effectively acknowledged and actioned!",
    },
  ],
  accounting: [
    {
      isDoubleSection: false,
      isSubContent: true,
      isHaveList: true,
      isHaveContent: true,
      category: "accounting",
      image: PropertyManagementImg18,
      title: "Generate and automate your receivables and any income payment",
      subTitle: "Whether you're an accounting professional or not, we've made accounting easy for you!",
      content:
        "Track all property receivables and income payments in one place and ensure you manage your cashflow accurately and with ease, making sure you boost",
      subContent:
        "Have a clear record of all financial transactions related to your properties and assess the financial health of your business by evaluating the risks of late or missed payments to make informed decisions about tenant selection and lease renewals.",
      list: [
        {
          listTitle: "Reduce administration burdens by automating...",
          listItems: [
            "Rent payments",
            "Reminders",
            "Invoices",
            "Payment statuses",
            "Late payment fees",
            "Pay their rent",
            "Upload documents",
          ],
        },
        {
          listTitle: "Record your receivables accurately and...",
          listItems: [
            "Develop better income forecasting",
            "Generate accurate financial reporting",
            "Allocate resources effectively",
            "Payment statuses",
            "Manage risks on overdue payments",
            "Comply with record keeping requirements",
          ],
        },
      ],
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: true,
      isHaveContent: true,
      category: "accounting",
      image: PropertyManagementImg19,
      title: "Keep your expenses in order and track all your payables efficiently",
      content:
        "Whether its property maintenance, utilities, government fees or any other expense, record all payables and property expenses and keep track of your costs, while managing and allocating your budgets efficiently and flexibly.",

      list: [
        {
          listTitle: "Keep a clear view and record of your existing and future financial obligations to...",
          listItems: [
            "Manage budgets efficiently",
            "Manage cashflow through timely payments",
            "Maintain healthy relationships with vendors and suppliers",
            "Control costs",
            "Identify cost-saving opportunities",
            "Avoid penalties",
            "Keep a clear audit trail",
          ],
        },
      ],
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "accounting",
      image: PropertyManagementImg20,
      title: "Statement Reports",
      content: "Track your income and expense balance easily and flexibly for any period of time!",
    },
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "accounting",
      image: PropertyManagementImg21,
      title: "Generate invoices, receipts and more!",
      content:
        "Never have a missing financial document again! You can now configure and customize your invoices, receipts, refunds and more, and streamline and integrate them in every financial workflow while they are stored in one place under your control!",
    },
  ],
  erp: [
    {
      isDoubleSection: false,
      isSubContent: true,
      isHaveList: false,
      isHaveContent: true,
      category: "erp",
      image: PropertyManagementImg22,
      title: "Workflows & Approvals",
      subTitle:
        "Whether it's a tenant moving in, maintenance request, or payment processing, you can easily set up, customize and manage your workflows and approval processes.",
      content:
        "With workflow management, you don't need to disrupt your business you can simply start from where you last left off and get up and running right away.",
      subContent: "You can even monitor your workflows and identify bottlenecks to optimize your processes!",
    },
    {
      isDoubleSection: false,
      isSubContent: true,
      isHaveList: false,
      isHaveContent: true,
      category: "erp",
      image: PropertyManagementImg23,
      title: "Workflows & Approvals",
      subTitle:
        "Whether it's a tenant moving in, maintenance request, or payment processing, you can easily set up, customize and manage your workflows and approval processes.",
      content:
        "With workflow management, you don't need to disrupt your business you can simply start from where you last left off and get up and running right away.",
      subContent: "You can even monitor your workflows and identify bottlenecks to optimize your processes!",
    },
  ],
  tasks: [
    {
      isDoubleSection: true,
      isSubContent: true,
      isHaveList: false,
      isHaveContent: true,
      category: "tasks",
      image: PropertyManagementImg24,
      title: "Organize and manage your daily tasks with ease",
      content: "Get your day to day organized and set your goals clearly by creating and updating your own tasks.",
      subContent: "To make things easier, you can create shortcuts to raised requests directly on to your task!",
      secondSection: {
        title: "Set reminders so you never miss a task again",
        content: "You can set reminders on approaching deadlines and make sure you have it all under control!",
      },
    },
  ],
  vendors: [
    {
      isDoubleSection: false,
      isSubContent: false,
      isHaveList: false,
      isHaveContent: true,
      category: "vendors",
      image: PropertyManagementImg25,
      title: "Manage your vendor relationships from one place",
      content:
        "Create your own vendor library of custom lists based on general or specific service needs that allow you to allocate the right requirement to the right team.",
    },
  ],
  security: [
    {
      securityItemsSectionOne: [
        {
          category: "security",
          icon: SecurityIcon1,
          title: "End-to-End Encryption",
          content:
            "All data is encrypted at all times whether at-rest or in-transit, using the highest encryption standards.",
        },
        {
          category: "security",
          icon: SecurityIcon2,
          title: "Data Backups",
          content: "We back up all data every 5 minutes to ensure data safety and recovery.",
        },
        {
          category: "security",
          icon: SecurityIcon3,
          title: "Unauthorized Access",
          content: "Firewalls are deployed to detect, block and protect data from unauthorized accesses.",
        },
        {
          category: "security",
          icon: SecurityIcon4,
          title: "Secure Online Payments",
          content:
            "Online payments done on Propertise are secure and Propertise will not have access to user card details.",
        },
        {
          category: "security",
          icon: SecurityIcon5,
          title: "Privacy",
          content:
            "Your data privacy is very important, so we've made sure that your data is encrypted so no one will have access to it.",
        },
        {
          category: "security",
          icon: SecurityIcon6,
          title: "Exporting Data",
          content: "You can easily back up any of your data by exporting it to your computer.",
        },
      ],
      securityItemsSectionTwo: [
        {
          category: "security",
          icon: SecurityIcon7,
          title: "2-Step Verifications",
          content:
            "Users will receive an OTP (One Time Password) with each log in to ensure account holders are verified at all times.",
        },
        {
          category: "security",
          icon: SecurityIcon8,
          title: "Multiple Session Authorization",
          content:
            "Users can set single or multiple log-in sessions giving them control over how many log-in sessions can occur at the same time across different devices.",
        },
        {
          category: "security",
          icon: SecurityIcon9,
          title: "Device Authorization",
          content: "Users can set and authorize the devices they want to log-in from using one main device.",
        },
        {
          category: "security",
          icon: SecurityIcon10,
          title: "Controlled Access Roles",
          content:
            "Control what each user is exposed to, by enabling or disabling what they can view on the portal. You can even control the actions they can take as well (i.e. create, edit, delete, data exports, etc.).",
        },
        {
          category: "security",
          icon: SecurityIcon11,
          title: "Activity Logs",
          content: "You can review the historical activities of users.",
        },
        {
          category: "security",
          icon: SecurityIcon12,
          title: "Unauthorized Log-in Detections",
          content:
            "Users are immediately notified for any unusual log-in activity that their account may experience and have the ability to act quick towards any unauthorized access attempts, keeping the account secure.",
        },
      ],
    },
  ],
}

// SOLUTION PAGE TABS
export const tabs = [
  {
    title: "Property Management",
    category: "propertyManagement",
  },
  {
    title: "Leasing",
    category: "leasing",
  },
  {
    title: "Tenants",
    category: "tenants",
  },
  {
    title: "Maintenance",
    category: "maintenance",
  },
  {
    title: "Accounting",
    category: "accounting",
  },
  {
    title: "ERP",
    category: "erp",
  },
  {
    title: "Tasks",
    category: "tasks",
  },
  {
    title: "Vendors",
    category: "vendors",
  },
  {
    title: "Security",
    category: "security",
  },
  {
    title: "Customer Support",
    category: "customerSupport",
  },
]

export const accordionItems = {
  firstSection: [
    {
      question: "How long does it take to resolve a support ticket?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper tortor nec habitasse vestibulum id amet at sit..",
    },
    {
      question: "How long does it take to resolve a support ticket?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper tortor nec habitasse vestibulum id amet at sit..",
    },
    {
      question: "How long does it take to resolve a support ticket?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper tortor nec habitasse vestibulum id amet at sit..",
    },
    {
      question: "How long does it take to resolve a support ticket?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper tortor nec habitasse vestibulum id amet at sit..",
    },
  ],
  secondSection: [
    {
      question: "How many support tickets can I rasise?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper tortor nec habitasse vestibulum id amet at sit..",
    },
    {
      question: "How many support tickets can I rasise?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper tortor nec habitasse vestibulum id amet at sit..",
    },
    {
      question: "How many support tickets can I rasise?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper tortor nec habitasse vestibulum id amet at sit..",
    },
    {
      question: "How many support tickets can I rasise?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper tortor nec habitasse vestibulum id amet at sit..",
    },
  ],
}

export const customerItemData = [
  {
    title: "User Guide",
    icon: CUIcon1,
    content:
      "You will have instant access to the Propertise user guide, which will provide you with all the details and step-by-step instructions on how to fully utilize the solutions and features you are using, while assisting you in clarifying your questions and requirements.",
    subContent:
      "The user guide is frequently updated to keep you informed on all current and future updates and advancements.",
  },
  {
    title: "Help & Support",
    icon: CUIcon2,
    content:
      "You can raise a request any time at your dedicated help and support counter available in your account. You will be able to track and follow up on the status of your request and view the responses of our support team.",
    subContent: "Our team will be happy to assist you, Monday to Friday from 8AM-6PM GMT.",
  },
  {
    title: "Onboarding & Training",
    icon: CUIcon2,
    content:
      "We care about your success as much as you do, and from the moment you join Propertise, you will be assigned an onboarding and training manager who will deliver a training session to you and your team and ensure you are all set up",
  },
]
